import { Box, Tooltip, Typography } from '@mui/material'
import { ContainerResponseDto } from '@planning/app/api'
import { useUnitTypeIcon, useUnitTypeLabel } from '@planning/components/UnitType'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { FC } from 'react'

interface IProps {
  container?: ContainerResponseDto
}

const ContainerAttribute: FC<{ label: string; attribute: string }> = ({ label, attribute }) => {
  const theme = useMinimalsTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant='caption' color={theme.palette.grey[600]}>
        {label}
      </Typography>
      <Typography variant='subtitle1' color={theme.palette.grey[600]}>
        {attribute}
      </Typography>
    </Box>
  )
}

export const ContainerSummary = ({ container }: IProps) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const unitTypeIcon = useUnitTypeIcon(container?.unitType)
  const unitTypeLabel = useUnitTypeLabel(container?.unitType)

  if (!container) return <></>

  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: theme.customRadius.medium,
        bgcolor: theme.palette.grey[200],
        padding: theme.customSpacing.m,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.customSpacing.s,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: theme.customSpacing.xs,
        }}
      >
        <Tooltip title={unitTypeLabel} placement='bottom'>
          {unitTypeIcon}
        </Tooltip>
        <Typography variant='h4'>{container.number}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: theme.customSpacing.xxs,
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: theme.customSpacing.xl,
          }}
        >
          {container.isoCode && (
            <ContainerAttribute label={t('isoCode', 'ISO Code')} attribute={container.isoCode} />
          )}
          {container.type && (
            <ContainerAttribute label={t('type', 'Type')} attribute={container.type} />
          )}
          {container.length && (
            <ContainerAttribute label={t('length', 'Length')} attribute={`${container.length}ft`} />
          )}
          {container.height && (
            <ContainerAttribute label={t('height', 'Height')} attribute={container.height} />
          )}
        </Box>
      </Box>
    </Box>
  )
}
