import { Stack, Typography } from '@mui/material'
import { JobDto } from '@operations/app/api'
import { EmptyIcon, FullIcon, useMinimalsTheme, WeightIcon } from '@tom-ui/ui'
import { getContainerHeight } from '../../utils'

export interface Props {
  job: JobDto
}
export const JobDescriptionMinimalistic = ({ job }: Props) => {
  const theme = useMinimalsTheme()

  if (!job.container) return <></>

  return (
    <Stack flexDirection='row' gap={theme.customSpacing.l}>
      <Stack flexDirection='row' alignItems='center' gap={theme.customSpacing.xs}>
        {job.container?.isEmpty ? <EmptyIcon /> : <FullIcon />}
        {!!job.container.length && !!job.container.height && (
          <Typography variant='body2'>
            {job.container.length} {getContainerHeight(job.container.height)}
          </Typography>
        )}
      </Stack>
      {!!job.container.grossWeight && (
        <Stack flexDirection='row' alignItems='center' gap={theme.customSpacing.xs}>
          <WeightIcon />
          <Typography variant='body2'>{`${job.container.grossWeight}kg`}</Typography>
        </Stack>
      )}
    </Stack>
  )
}
