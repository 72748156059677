import { Button, Card, CardContent, Stack } from '@mui/material'
import { JobDto } from '@operations/app/api'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { JobAlert } from '../JobCard/JobAlert'
import { JobContainerNumber } from '../JobCard/JobContainerNumber'
import { JobDescriptionMinimalistic } from './JobDescriptionMinimalistic'
import { JobJourneyMinimalistic } from './JobJourneyMinimalistic'

interface Props {
  job: JobDto
  isConfirm?: boolean
  highlightText?: string
  currentEquipmentId?: number
  onConfirm: (job: JobDto) => void
}
export const JobCardMinimalistic = ({
  job,
  isConfirm,
  onConfirm,
  highlightText,
  currentEquipmentId,
}: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const handleConfirm = () => {
    onConfirm(job)
  }

  return (
    <Card
      sx={{ width: '100%', height: '100%' }}
      data-cy={`job-card-${job.container?.containerNumber ?? 'non-numeric'}`}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.customSpacing.xs,
          height: '100%',
        }}
      >
        <JobAlert warningReason={job.warningReason} />

        <Stack
          flexDirection='row'
          justifyContent='space-between'
          alignItems='center'
          flexWrap='wrap'
        >
          <Stack gap={theme.customSpacing.xs}>
            <JobJourneyMinimalistic job={job} currentEquipmentId={currentEquipmentId} />
            <JobContainerNumber job={job} highlightText={highlightText} variant='h1' />
          </Stack>

          <Stack flexDirection='row' gap={theme.customSpacing.xll}>
            <JobDescriptionMinimalistic job={job} />

            <Button
              variant='text'
              color='primary'
              size='large'
              onClick={handleConfirm}
              disabled={job.isPlanned}
              data-cy='confirm-container-job-btn'
            >
              {isConfirm ? t('confirmMove', 'Confirm move') : t('startMove', 'Start move')}
            </Button>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}
