import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { useOperationsStore } from '@tom-ui/operations'
import {
  ContainerDamageRecordStep,
  ContainerOperations,
  ContainerRailOperations,
  HeaderAvatar,
  InspectContainer,
  ReportAccidentallyDischargedContainer,
  SearchContainers,
  useNavigationStore,
  usePlanningStore,
} from '@tom-ui/planning'
import { BackButton, ContainerMobile, Header, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { CheckType } from 'modules/planning/src/app/api'
import { WorkAssignmentsSelection } from 'modules/planning/src/pages/TallymanV2/Components/WorkAssignments/WorkAssignmentsSelection'
import moment from 'moment'
import { useEffect, useMemo } from 'react'
import { renderIsoCodeMappingInput } from '../IsoCodeMapping/IsoCodeMappingWrapper'
import { EquipmentSelection } from './EquipmentSelection'
import { EquipmentSelectionViewStore } from './EquipmentSelectionViewStore'

export const TallyclerkHomePage = observer(() => {
  const { t } = useTranslate()

  const {
    tallymanSelectVesselAndOperationViewStore: store,
    vesselVisitQueryService,
    railVisitQueryService,
    drawerStore,
    containerDamageReportViewStore,
  } = usePlanningStore()
  if (!store.railTallyStore || !store.vesselTallyStore) return <></>

  const { vesselTallyStore, railTallyStore } = store
  const { searchContainerStore, checkNotificationStore, reportNotificationStore } = vesselTallyStore

  const navigationStore = useNavigationStore()
  const { tallymanOperationsUIStore } = useOperationsStore()
  const theme = useMinimalsTheme()

  const equipmentSelectionStore = useMemo(
    () =>
      new EquipmentSelectionViewStore(
        tallymanOperationsUIStore,
        vesselTallyStore.checkNotificationStore,
      ),
    [tallymanOperationsUIStore, vesselTallyStore],
  )

  const onSelectEquipment = async (orderData: any, order: any) => {
    equipmentSelectionStore.checkOrder(orderData, order.data)
    navigationStore.clear()
    navigationStore.push(
      <ContainerOperations
        store={store}
        onSearchClick={onSearchContainers}
        onCancelCheckRequest={onCancelCheckRequest}
        openDrawer={openDrawer}
      />,
    )
  }

  const onCancelCheckRequest = () => {
    if (checkNotificationStore.order) checkNotificationStore.order.status = 'Open'
    equipmentSelectionStore.cancelRequest()
  }

  const onSubmitCheckedContainer = async (orderData: any) => {
    checkNotificationStore.createCheckInRequest(orderData, searchContainerStore.selectedOrder?.data)
    navigationStore.clear()
    navigationStore.push(
      <ContainerOperations
        store={store}
        onSearchClick={onSearchContainers}
        onCancelCheckRequest={onCancelCheckRequest}
        openDrawer={openDrawer}
      />,
    )
  }

  const onNavigateToSelectEquipment = (orderData: any) => {
    navigationStore.push(
      <EquipmentSelection
        orderData={orderData}
        tallymanViewStore={vesselTallyStore}
        equipmentSelectionStore={equipmentSelectionStore}
        onSelectEquipment={(orderData, order) => onSelectEquipment(orderData, order)}
      />,
    )
  }

  const onSelectContainer = (id: number) => {
    searchContainerStore.selectOrder(id)

    if (searchContainerStore.selectedOrder) {
      const { selectedOrder: order } = searchContainerStore
      navigationStore.push(
        <InspectContainer
          order={order.data}
          submitButtonText={t('next', 'Next')}
          damages={order.container?.data.damages}
          onSubmit={data => {
            if (data.hasDamage) return onReportDamage(data)

            if (order.data.direction === 'Inbound') return onNavigateToSelectEquipment(data)

            return onSubmitCheckedContainer(data)
          }}
        />,
      )
    }
  }

  const onReportDamage = (orderData: any) => {
    const { selectedOrder: order } = searchContainerStore

    navigationStore.push(
      <ContainerDamageRecordStep
        orderData={orderData}
        containerNumber={order?.data.containerNumber}
        hasNextStep={order?.data.direction === 'Inbound'}
        direction={order?.data.direction}
        onSubmit={(data, damages) => {
          data.damagesReported = damages

          return order?.data.direction === 'Inbound'
            ? onNavigateToSelectEquipment(data)
            : onSubmitCheckedContainer(data)
        }}
      />,
    )
  }

  const onSubmitReportedContainer = (cmd: any) => {
    reportNotificationStore.createReportRequest(cmd)

    navigationStore.clear()
    navigationStore.push(
      <ContainerOperations
        store={store}
        onSearchClick={onSearchContainers}
        onCancelCheckRequest={onCancelCheckRequest}
        openDrawer={openDrawer}
      />,
    )
  }

  const onReportContainer = () => {
    if (vesselTallyStore.selectedVisitId)
      navigationStore.push(
        <ReportAccidentallyDischargedContainer
          visitId={vesselTallyStore.selectedVisitId}
          onSubmit={onSubmitReportedContainer}
          onClose={navigationStore.pop}
        />,
      )
  }

  const onSearchContainers = () => {
    searchContainerStore.setSearchFilter('')
    checkNotificationStore.reset()
    reportNotificationStore.reset()

    navigationStore.push(
      <SearchContainers
        store={vesselTallyStore.searchContainerStore}
        onContainerCardClick={onSelectContainer}
        onReportClick={onReportContainer}
      />,
    )
  }

  const onSelectVesselOperation = (op: any) => {
    vesselTallyStore.selectOperation(op)

    if (vesselTallyStore.selectedVisit)
      navigationStore.push(
        <ContainerOperations
          store={store}
          onSearchClick={onSearchContainers}
          onCancelCheckRequest={onCancelCheckRequest}
          openDrawer={openDrawer}
        />,
      )
  }

  const onSelectTrainOperation = (op: CheckType) => {
    railTallyStore.selectOperationType(op)
    navigationStore.push(<ContainerRailOperations openDrawer={openDrawer} store={railTallyStore} />)
  }

  const openDrawer = () => {
    drawerStore.showView(
      <Box padding={1.5}>
        <WorkAssignmentsSelection
          store={store}
          onSelectTrainOperation={operation => {
            navigationStore.clear()
            onSelectTrainOperation(operation)
            drawerStore.close()
          }}
          onSelectVesselOperation={operation => {
            navigationStore.clear()
            onSelectVesselOperation(operation)
            drawerStore.close()
          }}
          renderIsoCodeMappingInput={renderIsoCodeMappingInput}
        />
      </Box>,
      {},
      'left',
      true,
    )
  }

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        vesselVisitQueryService.fetch({
          from: moment().subtract(30, 'day').toDate(),
          to: moment().endOf('d').toDate(),
        }),

        railVisitQueryService.fetch({
          from: moment().subtract(30, 'day').toDate(),
          to: moment().endOf('d').toDate(),
        }),

        containerDamageReportViewStore.fetch(),
      ])
    }

    fetchData()
  }, [vesselVisitQueryService, railVisitQueryService, containerDamageReportViewStore])

  return (
    <Box
      style={{
        backgroundColor: theme.palette.grey[200],
        minHeight: 'calc(100vh - 65px)',
      }}
    >
      <Header
        title='Tally clerk'
        rightRenderOption={
          drawerStore.open ? (
            <BackButton onGoBack={drawerStore.close} tooltip={t('return', 'Return')} />
          ) : (
            <HeaderAvatar />
          )
        }
      />
      <ContainerMobile sx={{ pt: theme.customSpacing.l }}>
        <Typography variant='h6' gutterBottom>
          {t('workAssignments', 'Work assignments')}
        </Typography>
        <WorkAssignmentsSelection
          store={store}
          onSelectVesselOperation={onSelectVesselOperation}
          onSelectTrainOperation={onSelectTrainOperation}
          renderIsoCodeMappingInput={renderIsoCodeMappingInput}
        />
      </ContainerMobile>
    </Box>
  )
})
