import DashboardCard from '@storage/components/DashboardCard'
import { Box, Button, Tooltip } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { PATH_STORAGE } from '@storage/routes'
import { DashboardCardType } from '../types/yard-planning-dashboard.types'
import { getDashboardCardSubtitle } from '../utils/yard-planning-dashboard.util'
import { Language } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useBooleanFlagValue } from '@openfeature/react-sdk'

interface YardPlanningDashboardHeaderProps {
  totalYardBlock: number
  totalContainerTurnovers: number
  totalIssues?: number
  yardInconsistencies: number
}
const YardPlanningDashboardHeader = observer(
  ({
    totalYardBlock,
    totalContainerTurnovers,
    yardInconsistencies,
  }: YardPlanningDashboardHeaderProps) => {
    const { t } = useTranslate()
    const navigate = useNavigate()

    const isGlobalAllocationRulesFeatureEnabled = useBooleanFlagValue(
      'global-allocation-rules',
      false,
    )

    return (
      <Box sx={{ display: 'flex', gap: 3, p: 1 }}>
        <DashboardCard
          sxProps={{ minWidth: '382px' }}
          title={t('yardView', 'Yard View')}
          subtitle={getDashboardCardSubtitle(DashboardCardType.Block, totalYardBlock)}
          actionRoute={PATH_STORAGE.yardManagement}
        />

        <DashboardCard
          sxProps={{ minWidth: '382px' }}
          title={t('containerTurnovers', 'Container Turnovers')}
          subtitle={getDashboardCardSubtitle(DashboardCardType.Container, totalContainerTurnovers)}
          actionRoute={PATH_STORAGE.containerTurnovers}
        />

        {/* <DashboardCard
        sxProps={{ width: '382px' }}
        title={t('issues', 'Issues')}
        subtitle={<LabelBox label={`${totalIssues} ${t('inTotal', 'in Total')}`} />}
        icon={ReportProblem}
        actionRoute={PATH_STORAGE.yardPlanningIssues}
      /> */}

        <DashboardCard
          sxProps={{ minWidth: '382px' }}
          title={t('yardInconsistencies', 'Yard Inconsistencies')}
          subtitle={getDashboardCardSubtitle(DashboardCardType.Issue, yardInconsistencies)}
          subtitleColor={yardInconsistencies > 0 ? 'warning' : 'secondary'}
          actionRoute={PATH_STORAGE.yardInconsistencies}
          isActionDisabled={yardInconsistencies === 0}
        />

        {isGlobalAllocationRulesFeatureEnabled && (
          <Tooltip
            title={t(
              'globalRulesTooltip',
              'Great for containers that come to the terminal and always go to the same spaces. You can override global rules when planning individual visits.',
            )}
          >
            <Button
              startIcon={<Language />}
              onClick={() => navigate(PATH_STORAGE.globalAllocationRules)}
            >
              {t('globalRules', 'Global Rules')}
            </Button>
          </Tooltip>
        )}
      </Box>
    )
  },
)

export default YardPlanningDashboardHeader
