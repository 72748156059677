import { IsoCodeMappingResponseDto } from '@admin/app/api'
import { useAdminStore } from '@admin/AppProvider'
import { Box } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { IsoCodeMappingFormDialog } from './components/IsoCodeMappingForm'
import { IsoCodeMappingHeader } from './components/IsoCodeMappingHeader'
import { IsoCodeMappingList } from './components/IsoCodeMappingList'

export const IsoCodeMappings: FC<{
  onFilter: (filter: string) => Promise<IsoCodeMappingResponseDto>
}> = observer(({ onFilter }) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()
  const { isoCodeMappingUIStore } = useAdminStore()

  useEffect(() => {
    isoCodeMappingUIStore.isoCodeMappingStore.load()
  }, [isoCodeMappingUIStore.isoCodeMappingStore])

  return (
    <Box
      sx={{
        height: '100%',
        bgcolor: theme.palette.grey[100],
      }}
    >
      <Helmet>
        <title>{t('isoCodeMapping', 'ISO Code Mapping')}</title>
      </Helmet>
      <IsoCodeMappingHeader viewStore={isoCodeMappingUIStore} onFilter={onFilter} />
      <IsoCodeMappingList viewStore={isoCodeMappingUIStore} />
      <IsoCodeMappingFormDialog viewStore={isoCodeMappingUIStore} onFilter={onFilter} />
    </Box>
  )
})
