import { Warning } from '@mui/icons-material'
import { Badge } from '@mui/material'
import { usePlanningStore } from '@tom-ui/planning'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'

export const IssueMenuIcon = observer(() => {
  const { issueItemStore } = usePlanningStore()

  useEffect(() => {
    issueItemStore.fetch()
  }, [issueItemStore])

  const totalIssueCount = useMemo(
    () => issueItemStore.notResolvedItemsWithObjectTypeOrder.length,
    [issueItemStore.notResolvedItemsWithObjectTypeOrder.length],
  )

  return (
    <Badge badgeContent={totalIssueCount} color='warning'>
      <Warning />
    </Badge>
  )
})
