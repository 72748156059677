/* eslint-disable no-restricted-globals */
import { IssueMenuIcon } from '@host/components/nav-section/IssueMenuIcon'
import { RouteItem } from '@host/components/nav-section/types'
import { SpaceDashboard } from '@mui/icons-material'
import DashboardIcon from '@mui/icons-material/Dashboard'
import WarehouseIcon from '@mui/icons-material/Warehouse'
import {
  GeneralIcon,
  JobsIcon,
  MapIcon,
  RegistersIcon,
  TrainIcon,
  TruckIcon,
  UsersIcon,
  VesselIcon,
  VisitsIcon,
} from '@tom-ui/ui'
import { DeliveryOrdersIcon, ReleaseOrdersIcon } from '../icons'
import { GC_HUB, JOBS, LAYOUT, OPERATOR_VIEWS, PATHS, REGISTERS } from './paths'
import Permission from './permission.enum'

interface Props {
  showUnifiedOperatorPage: boolean
  skipVesselTally: boolean
  skipRailTally: boolean
  hasGeneralCargo: boolean
  hasDamageCatalogue: boolean
}

export const getNavitems = ({
  showUnifiedOperatorPage,
  skipVesselTally,
  skipRailTally,
  hasGeneralCargo,
  hasDamageCatalogue,
}: Props): RouteItem[] => [
  {
    id: 'dashboard',
    title: 'dashboard',
    path: PATHS.dashboard,
    requiredFeatureFlag: { name: 'dashboard-page', enable: true },
    icon: <SpaceDashboard sx={{ width: 24 }} />,
  },
  {
    id: 'visits',
    title: 'visits',
    path: PATHS.visits,
    requiredPermission: Permission.ReadVessels,
    requiredFeatureFlag: { name: 'visits-page', enable: true },
    icon: <VisitsIcon sx={{ width: 24 }} />,
  },
  {
    title: 'vesselVisits',
    path: PATHS.vesselVisits,
    requiredPermission: Permission.ReadVessels,
    requiredFeatureFlag: { name: 'visits-page', enable: false },
    icon: <VesselIcon viewBox='0 0 20 14' sx={{ width: 24 }} />,
  },
  {
    title: 'railVisits',
    path: PATHS.railVisits,
    // [OCTA-706] TODO: Permission
    requiredPermission: Permission.ReadVessels,
    requiredFeatureFlag: { name: 'visits-page', enable: false },
    icon: <TrainIcon sx={{ width: 24 }} />,
  },
  {
    title: 'gateControl',
    path: PATHS.gateControl,
    icon: <TruckIcon />,
  },
  {
    title: 'Orders',
    path: PATHS.containerVisits,
    requiredPermission: Permission.ReadOrders,
    icon: <GeneralIcon />,
    children: [
      {
        title: 'Numeric containers',
        path: PATHS.containerVisits,
        requiredPermission: Permission.ReadOrders,
      },
      {
        title: 'Non-numeric containers',
        description: 'non-numericReleases',
        path: PATHS.nnrOrders,
        requiredPermission: Permission.ReadOrders,
      },
      {
        title: 'General cargo',
        description: 'general-cargo',
        path: PATHS.generalCargoOrders,
        requiredPermission: Permission.ReadOrders,
        hidden: !hasGeneralCargo,
      },
      {
        title: 'Service orders',
        path: PATHS.serviceOrders,
        requiredPermission: Permission.ReadOrders,
      },
    ],
  },

  {
    title: 'pick-up',
    description: 'pick-upOrders',
    path: PATHS.releaseOrders,
    requiredPermission: Permission.ReadOrders,
    icon: <ReleaseOrdersIcon />,
    requiredFeatureFlag: { name: 'pick-up-order', enable: true },
  },
  {
    title: 'drop-off',
    description: 'drop-offOrders',
    path: PATHS.deliveryOrders,
    requiredPermission: Permission.ReadOrders,
    icon: <DeliveryOrdersIcon />,
    requiredFeatureFlag: { name: 'drop-off-order', enable: true },
  },
  {
    id: 'yard-planning',
    title: 'yardPlanning',
    description: 'yardPlanningDashboard',
    path: PATHS.yardPlanningDashboard.root,
    icon: <DashboardIcon />,
  },
  {
    title: 'generalCargoHub',
    path: GC_HUB,
    hidden: !hasGeneralCargo,
    icon: <WarehouseIcon />,
    children: [
      {
        title: 'generalCargoAreas',
        path: PATHS.generalCargoHub.generalCargoAreas,
      },
      {
        title: 'generalCargoInventory',
        path: PATHS.generalCargoHub.generalCargoInventory,
      },
    ],
  },
  {
    id: 'operators',
    title: 'operators',
    description: 'operatorViews',
    path: OPERATOR_VIEWS,
    icon: <UsersIcon />,
    children: [
      {
        id: 'tally-clerk',
        title: 'tallyClerk',
        path: `${new URL(PATHS.tallyclerk, location.origin)}`,
        requiredPermission: Permission.ReadVessels,
        hidden: skipVesselTally && skipRailTally,
      },
      {
        id: 'gate-clerk',
        title: 'gateClerk',
        path: `${new URL(PATHS.gateClerk, location.origin)}`,
      },
      {
        title: 'bayProfile',
        path: PATHS.crane,
        requiredPermission: Permission.StartFinishJobs,
      },
      {
        id: 'operators-sts',
        title: 'STS',
        path: `${new URL(PATHS.sts, location.origin)}`,
        requiredPermission: Permission.StartFinishJobs,
        hidden: showUnifiedOperatorPage || !skipVesselTally,
      },
      {
        id: 'operators-tt',
        title: 'TT',
        path: `${new URL(PATHS.tt, location.origin)}`,
        requiredPermission: Permission.StartFinishJobs,
        hidden: showUnifiedOperatorPage,
      },
      {
        title: 'RTG',
        path: `${new URL(PATHS.rtg, location.origin)}`,
        requiredPermission: Permission.StartFinishJobs,
        hidden: showUnifiedOperatorPage,
      },
      {
        title: 'RMG',
        path: `${new URL(PATHS.rmg, location.origin)}`,
        requiredPermission: Permission.StartFinishJobs,
        hidden: showUnifiedOperatorPage,
      },
      {
        id: 'operators-rs',
        title: 'RS',
        path: `${new URL(PATHS.rs, location.origin)}`,
        requiredPermission: Permission.StartFinishJobs,
        hidden: showUnifiedOperatorPage,
      },
      {
        title: 'emptyHandlerRS',
        path: `${new URL(PATHS.ech, location.origin)}`,
        requiredPermission: Permission.StartFinishJobs,
        hidden: showUnifiedOperatorPage,
      },
      {
        title: 'equipmentOperator',
        path: `${new URL(PATHS.all, location.origin)}`,
        requiredPermission: Permission.StartFinishJobs,
        hidden: !showUnifiedOperatorPage,
      },
      {
        title: 'generalCargoOperator',
        path: `${new URL(PATHS.gc, location.origin)}`,
        requiredPermission: Permission.StartFinishJobs,
        hidden: !hasGeneralCargo,
      },
      {
        title: 'reeferOperator',
        path: `${new URL(PATHS.reeferOperator, location.origin)}`,
        requiredPermission: Permission.StartFinishJobs,
      },
    ],
  },
  {
    id: 'jobs',
    title: JOBS,
    path: JOBS,
    icon: <JobsIcon />,
    children: [
      {
        id: 'jobs-workInstructions',
        title: 'workInstructions',
        path: PATHS.jobs.workInstructions,
      },
      {
        title: 'reeferMonitoring',
        path: PATHS.jobs.reeferMonitoring,
        requiredPermission: Permission.ReadReeferTemperatures,
      },
    ],
  },
  {
    id: 'registers',
    title: REGISTERS,
    path: REGISTERS,
    icon: <RegistersIcon />,
    children: [
      {
        title: 'customers',
        path: PATHS.registers.customers,
        requiredPermission: Permission.ReadCustomers,
      },
      {
        title: 'equipments',
        path: PATHS.registers.equipments,
        requiredPermission: Permission.EquipmentsAssignment,
      },

      {
        title: 'reports',
        path: PATHS.registers.reports,
        requiredPermission: Permission.ReadBillableReport,
      },
      {
        id: 'reset-data',
        title: 'resetData',
        path: PATHS.registers.reset,
        requiredPermission: Permission.ResetData,
      },
      {
        title: 'tenants',
        path: PATHS.registers.tenants,
        requiredPermission: Permission.ReadAdminTenant,
      },
      {
        title: 'tenantConfiguration',
        path: PATHS.registers.tenantConfiguration,
        requiredPermission: Permission.ReadTenants,
        requiredFeatureFlag: { name: 'tenant-configuration-page', enable: true },
      },
      {
        title: 'users',
        path: PATHS.registers.users,
        requiredPermission: Permission.ReadUsers,
      },
      {
        title: 'vessels',
        path: PATHS.registers.vessels,
        requiredPermission: Permission.ReadVessels,
      },
      {
        title: 'railcars',
        path: PATHS.registers.railcars,
      },
      {
        title: 'commoditiesAndPackaging',
        path: PATHS.registers.commoditiesAndPacking,
        requiredPermission: Permission.ReadCommodities,
        hidden: !hasGeneralCargo,
      },
      {
        title: 'allocationRuleTemplates',
        path: PATHS.registers.allocationRuleTemplates,
        requiredFeatureFlag: { name: 'allocation-rules', enable: true },
      },
      {
        title: 'weightClasses',
        path: PATHS.registers.weightClasses,
      },
      {
        title: 'damageCatalogue',
        path: PATHS.registers.damageCatalogue,
        requiredPermission: Permission.ReadDamageCatalogue,
        requiredFeatureFlag: { name: 'damage-recording', enable: true },
        hidden: !hasDamageCatalogue,
      },
      {
        title: 'dangerousGoodsSettings',
        path: PATHS.registers.dangerousGoodsSettings,
      },
      {
        title: 'isoCodeMapping',
        path: PATHS.registers.isoCodeMappings,
      },
    ],
  },
  {
    title: LAYOUT,
    path: LAYOUT,
    icon: <MapIcon />,
    children: [
      {
        title: 'piers',
        path: PATHS.layout.piers,
        requiredPermission: Permission.ReadPiers,
      },
      {
        title: 'berths',
        path: PATHS.layout.berths,
        requiredPermission: Permission.ReadBerths,
      },
      {
        title: 'railTracks',
        path: PATHS.layout.railTracks,
      },
      {
        title: 'yardSetup',
        path: PATHS.layout.yardSetup,
        requiredPermission: Permission.ReadYardBlocks,
      },
    ],
  },
  {
    title: 'issues',
    description: 'issues',
    path: PATHS.issues,
    icon: <IssueMenuIcon />,
    requiredFeatureFlag: { name: 'enable-issues-page', enable: true },
  },
]
