import DownloadIcon from '@mui/icons-material/Download'
import { Button, Stack, Tooltip } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { CargoType, CarrierType, CarrierVisitDirection, OrderResponseDto } from '@planning/app/api'
import { AddOrderButton } from '@planning/components/AddOrderButton'
import { DischargeOrderFilter } from '@planning/components/DischargeOrderFilter'
import { FeatureFlag } from '@planning/components/FeatureFlags'
import { GridToolbar } from '@planning/components/GridToolbar'
import { LoadOrderFilter } from '@planning/components/LoadOrderFilter'
import { UploadOrdersButton } from '@planning/components/UploadOrdersButton'
import { CreateOrdersPage } from '@planning/pages/Order'
import { CreateGeneralCargoOrder } from '@planning/pages/Order/CreateGeneralCargoOrders'
import { IVesselVisitItem } from '@planning/pages/Order/stores/ContainerVisitItem'
import { IAmAContainerVisitToo } from '@planning/pages/Order/stores/SelectOrderViewStore'
import { IRailVisitItem } from '@planning/rt-stores/railVisit/RailVisitItem'
import { IPaginatedStoreWithItems } from '@planning/stores/PaginatedStore'
import { ValidateOutboundOrderFunc } from '@planning/stores/gateControl/ValidateOutboundDto'
import { RailcarSequencingStore } from '@planning/stores/railcarSequencing/RailcarSequencingStore'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useMemo } from 'react'
import { ReviewUpdatesDialogViewStore } from '../Stores/ReviewUpdatesDialogViewStore'
import { OrderListUploadDialogV2 } from './Upload/OrderListUploadDialog'
import { ReviewUpdatesBtn } from './atoms/ReviewUpdatesBtn'
import { ReviewUpdatesDialog } from './organisms/ReviewUpdatesDialog'

interface Props {
  store: IPaginatedStoreWithItems<OrderResponseDto>
  direction: CarrierVisitDirection
  visitId?: number
  cargoType?: CargoType
  visitType: CarrierType
  validationOutboundRequest?: ValidateOutboundOrderFunc
  onClickExportOrders?: () => void
}

export const OrderListGridToolbar: FC<Props> = observer(
  ({
    store,
    direction,
    visitId,
    visitType,
    cargoType,
    validationOutboundRequest,
    onClickExportOrders,
  }) => {
    const {
      dialogStore,
      drawerStore,
      orderListUploadViewStoreV2,
      orderItemStore,
      orderUpdateItemStore,
      vesselVisitItemStore,
      railVisitItemStore,
      generalCargoViewStore,
    } = usePlanningStore()
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    const handleUpload = async () => {
      if (!visitId) return

      orderListUploadViewStoreV2.reset()
      if (visitType === CarrierType.Train) {
        await orderListUploadViewStoreV2.setRailVisitId(visitId)
      } else {
        await orderListUploadViewStoreV2.setVesselVisitId(visitId)
      }
      orderListUploadViewStoreV2.setDirection(direction)
      orderListUploadViewStoreV2.setOpen(true)
    }

    const handleShowFilter = () => {
      if (direction === CarrierVisitDirection.Inbound) {
        drawerStore.showView(<DischargeOrderFilter store={store} />)
      } else {
        drawerStore.showView(<LoadOrderFilter store={store} />)
      }
    }

    const isContainerVisit = cargoType === CargoType.Container

    const handleShowCreateOrderForm = () => {
      if (!visitId) return

      let carrierVisitItem: IVesselVisitItem | IRailVisitItem

      if (visitType === CarrierType.Train) {
        carrierVisitItem = computed(() => railVisitItemStore.elements[visitId]).get()
      } else {
        carrierVisitItem = computed(() => vesselVisitItemStore.elements[visitId]).get()
      }

      // TODO: Figure out what this is being used for! Object is bronken and does not appear to be used correctly
      const containerVisitOrderItem = {
        visit: carrierVisitItem.data,
        order: {
          carrierVisitId: visitId,
          carrierVisitType: visitType,
          direction: direction,
        },
      } as any

      const inbound =
        direction === CarrierVisitDirection.Inbound ? containerVisitOrderItem : undefined

      const outbound =
        direction === CarrierVisitDirection.Outbound ? containerVisitOrderItem : undefined

      const containerVisit: IAmAContainerVisitToo = [inbound, outbound]

      generalCargoViewStore.getPackages()
      generalCargoViewStore.selectOrder()

      dialogStore.openDialog(
        isContainerVisit ? (
          <CreateOrdersPage forcedDirection={direction} containerVisit={containerVisit} />
        ) : (
          <CreateGeneralCargoOrder
            visit={containerVisit}
            validateOutboundRequest={validationOutboundRequest}
          />
        ),
      )
    }

    const isUploadDisabled = useMemo(() => {
      let isSomeRailcarCheckedIn = false

      if (visitType === CarrierType.Train) {
        const railStore = store as RailcarSequencingStore
        const items =
          direction === CarrierVisitDirection.Inbound
            ? railStore.entities?.discharge?.items
            : railStore.entities?.load?.items
        const itemsArray = Object.values(items || {})
        isSomeRailcarCheckedIn = itemsArray.some(item => item.content.checkedIn)
      }

      return isSomeRailcarCheckedIn
    }, [direction, store, visitType])

    const reviewUpdatesDialogStore = useMemo(
      () => new ReviewUpdatesDialogViewStore(orderUpdateItemStore),
      [orderUpdateItemStore],
    )

    useEffect(() => {
      const orders = orderItemStore.ordersByCarrierVisitId[visitId!] ?? []
      const filteredOrders: OrderResponseDto[] = orders
        .filter(i => i.data.direction === direction)
        .map(i => i.data)

      if (filteredOrders.length > 0) {
        reviewUpdatesDialogStore.setOrders(filteredOrders)
      }
    }, [reviewUpdatesDialogStore, visitId, orderItemStore.ordersByCarrierVisitId, direction])

    return (
      <>
        <GridToolbar
          store={store}
          onShowFilter={() => handleShowFilter()}
          actions={
            <Stack direction={'row'} gap={theme.customSpacing.xs}>
              <FeatureFlag name='review-order-updates'>
                {visitType === CarrierType.Vessel && reviewUpdatesDialogStore.updatesCount > 0 && (
                  <ReviewUpdatesBtn
                    onClick={() => reviewUpdatesDialogStore.openDialog()}
                    updateCount={reviewUpdatesDialogStore.updatesCount}
                  />
                )}
              </FeatureFlag>
              <AddOrderButton onClick={handleShowCreateOrderForm} />
              <FeatureFlag name='export-csv-rail-visit-loading-list'>
                {onClickExportOrders && (
                  <Tooltip title={t('exportOrders', 'Export orders')}>
                    <Button
                      startIcon={<DownloadIcon />}
                      size='large'
                      color='inherit'
                      onClick={onClickExportOrders}
                      variant='contained'
                    >
                      {t('export', 'Export')}
                    </Button>
                  </Tooltip>
                )}
              </FeatureFlag>

              {isContainerVisit && (
                <UploadOrdersButton
                  onClick={handleUpload}
                  disabled={isUploadDisabled}
                  customTooltipTitle={
                    isUploadDisabled
                      ? t(
                          'cantUpload',
                          'You can no longer upload files because the inspection already started',
                        )
                      : t('uploadOrders', 'Upload orders')
                  }
                />
              )}
            </Stack>
          }
        />
        {isContainerVisit && (
          <OrderListUploadDialogV2
            visitType={visitType}
            enableFieldSelectionFromOrdersView={false}
            open={orderListUploadViewStoreV2.open}
            handleDialogClose={() => orderListUploadViewStoreV2.reset()}
          />
        )}

        <FeatureFlag name='review-order-updates'>
          {visitType === CarrierType.Vessel && (
            <ReviewUpdatesDialog store={reviewUpdatesDialogStore} />
          )}
        </FeatureFlag>
      </>
    )
  },
)
