import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { OrderResponseDto } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { EditOrdersPage, HandleOrderPageLocationState } from '@planning/pages/Order'
import { getVisitOrderPagePath, getVisitOrderPageReturnPath } from '@planning/utils/visit-utils'
import { useTranslate } from '@tolgee/react'
import { useNavigate } from 'react-router'

export const useOrderNavigation = () => {
  const navigate = useNavigate()
  const { t } = useTranslate()
  const { appViewStore, orderItemStore, selectOrderViewStore, dialogStore } = usePlanningStore()

  const isOrderPageFfEnabled = useBooleanFlagValue('orders-page', false)

  const openModal = async (order: OrderResponseDto) => {
    const { inbound, outbound } = await orderItemStore.getOrderContainerVisitDetails(order)

    if (!inbound && !outbound) return

    selectOrderViewStore.selectContainerVisit([inbound, outbound])
    if (order.containerId) selectOrderViewStore.setContainerId(order.containerId)

    // This file is a .tsx file, so it can contain JSX code
    // After removing the feature flag, the code below must also be removed and the file must be renamed to .ts
    dialogStore.openDialog(<EditOrdersPage forcedDirection={order.direction} />)
  }

  const navigateOrderPage = async (order: OrderResponseDto) => {
    if (!order.carrierVisitType || !order.carrierVisitId) {
      appViewStore.setShowAlert('error', t('failedToEditOrder', 'Failed to edit order'))
      return
    }

    const { carrierVisitType, carrierVisitId, direction, id: orderId } = order

    const orderPath = getVisitOrderPagePath(carrierVisitType, carrierVisitId, orderId)
    const returnPath = getVisitOrderPageReturnPath(carrierVisitType, direction, carrierVisitId)

    const state: HandleOrderPageLocationState = {
      returnPath,
    }

    navigate(orderPath, { state })
  }

  const openEditOrder = async (order: OrderResponseDto) => {
    if (isOrderPageFfEnabled) {
      navigateOrderPage(order)
    } else {
      openModal(order)
    }
  }

  return { openEditOrder }
}
