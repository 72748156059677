import { IssueStatus, OrderIssueResolutionType } from '@planning/app/api'
import { action, makeObservable, observable } from 'mobx'
import { IIssueItem } from './IssueItem'

export interface IIssueResolutionFormStore<T> {
  validate: () => boolean
  showErrors: boolean
  data?: T
}

export class IssueResolutionViewStore {
  isOpen = false
  issueToBeResolved?: IIssueItem
  selectedIssueResolution?: OrderIssueResolutionType
  issueResolutionStore?: IIssueResolutionFormStore<any>

  constructor() {
    makeObservable(this, {
      isOpen: observable,
      issueToBeResolved: observable,
      selectedIssueResolution: observable,
      issueResolutionStore: observable,
      openDialog: action,
      closeDialog: action,
      setSelectedIssueResolution: action,
    })
  }

  openDialog = (issue: IIssueItem) => {
    this.issueToBeResolved = issue
    this.issueResolutionStore = undefined
    this.selectedIssueResolution = undefined
    this.isOpen = true
  }

  closeDialog = () => {
    this.isOpen = false
    this.issueToBeResolved = undefined
    this.issueResolutionStore = undefined
    this.selectedIssueResolution = undefined
  }

  setShowErrors = (value: boolean) => {
    if (this.issueResolutionStore) {
      this.issueResolutionStore.showErrors = value
    }
  }

  setSelectedIssueResolution = (
    value: OrderIssueResolutionType,
    issueResolutionStore?: IIssueResolutionFormStore<any>,
  ) => {
    this.selectedIssueResolution = value
    this.issueResolutionStore = issueResolutionStore
  }

  validate = () => {
    if (
      !this.selectedIssueResolution &&
      this.issueToBeResolved?.data.issueStatus !== IssueStatus.FollowUp
    ) {
      return false
    }

    if (this.issueResolutionStore) {
      return this.issueResolutionStore.validate()
    }

    return true
  }

  resolutionData = () => {
    return this.issueResolutionStore?.data
  }
}
