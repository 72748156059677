import { IssueStatus, IssueType, OrderIssueResolutionType } from '@planning/app/api'
import { FieldboxWithRadio } from '@planning/components/atoms/FieldboxWithRadio'
import { FieldboxSelectionGroup } from '@planning/components/molecules/FieldboxSelectionGroup'
import { useTranslate } from '@tolgee/react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { ChangeTransportFormStore } from '../../Stores/ChangeTransportFormStore'
import { IssueResolutionViewStore } from '../../Stores/IssueResolutionViewStore'
import { ChangeTransportForm } from './ChangeTransportForm'

interface IProps {
  store: IssueResolutionViewStore
}

export const ResolveIssuesFormList = observer(({ store }: IProps) => {
  const { t } = useTranslate()
  const isFollowUp = store.issueToBeResolved?.data.issueStatus === IssueStatus.FollowUp
  const changeTransportStore = useLocalObservable(() => new ChangeTransportFormStore())

  const handleSelect = (key: string) => {
    if (
      key === OrderIssueResolutionType.ChangeInboundTransport ||
      key === OrderIssueResolutionType.ChangeOutboundTransport
    ) {
      store.setSelectedIssueResolution(key as OrderIssueResolutionType, changeTransportStore)
    } else {
      store.setSelectedIssueResolution(key as OrderIssueResolutionType)
    }
  }

  const issueType = store.issueToBeResolved?.data.issueType

  if (!issueType) return <></>

  return (
    <FieldboxSelectionGroup
      onSelect={handleSelect}
      defaultValue={isFollowUp ? OrderIssueResolutionType.FollowUp : ''}
      options={controller => (
        <>
          {issueType === IssueType.MissingRailcar && (
            <FieldboxWithRadio
              selectionKey={OrderIssueResolutionType.ChangeOutboundTransport}
              controller={controller}
              label={t('changeOutbountTranport', 'Change outbound transport')}
            >
              <ChangeTransportForm store={changeTransportStore} />
            </FieldboxWithRadio>
          )}
          {issueType === IssueType.Shortlanded && (
            <FieldboxWithRadio
              selectionKey={OrderIssueResolutionType.ChangeInboundTransport}
              controller={controller}
              label={t('changeInbountTranport', 'Change inbound transport')}
            >
              <ChangeTransportForm store={changeTransportStore} />
            </FieldboxWithRadio>
          )}
          {issueType === IssueType.MissingRailcar ||
            (issueType === IssueType.Shortlanded && (
              <FieldboxWithRadio
                selectionKey={OrderIssueResolutionType.DeleteOrder}
                controller={controller}
                label={t('removeOrder', 'Remove order')}
              />
            ))}
          {issueType === IssueType.MissingRailcar && (
            <FieldboxWithRadio
              selectionKey={OrderIssueResolutionType.RailcarFound}
              controller={controller}
              label={t('railcarWasFound', 'Railcar was found')}
            />
          )}
          {issueType === IssueType.Shortlanded && (
            <FieldboxWithRadio
              selectionKey={OrderIssueResolutionType.ContainerFound}
              controller={controller}
              label={t('containerFound', 'Container found on original railcar')}
            />
          )}
          <FieldboxWithRadio
            selectionKey={OrderIssueResolutionType.FollowUp}
            controller={controller}
            label={t('markForFollowUp', 'Mark for follow up')}
          />
        </>
      )}
    />
  )
})
