import { Typography } from '@mui/material'
import { Stack, SxProps, Theme } from '@mui/system'
import { useMinimalsTheme } from '../../../hooks'
import { ColorSchema } from '../../palette'

interface Props {
  label?: string
  color: ColorSchema
  startIcon?: React.ReactElement
  endIcon?: React.ReactElement
  sx?: SxProps<Theme>
}
export const ColoredLabel = ({ label, color, startIcon, endIcon, sx }: Props) => {
  const theme = useMinimalsTheme()
  return (
    <Stack
      gap={theme.customSpacing.xxs}
      direction={'row'}
      alignItems={'center'}
      sx={{
        backgroundColor: theme.palette[color].lighter,
        color: theme.palette[color].dark,
        padding: `${theme.customSpacing.xxs} ${theme.customSpacing.xs}`,
        borderRadius: theme.customRadius.small,
        ...sx,
      }}
    >
      {startIcon}
      {label && <Typography variant='captionBold'>{label}</Typography>}
      {endIcon}
    </Stack>
  )
}
