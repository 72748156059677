import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material'
import { CarrierVisitDirection, StackDto } from '@storage/app/api'
import { mapYardPositionDtoToYardPositionDescriptor } from '@storage/features/carrier-visit-allocation-rules/forms/allocation-rule-templates-form/allocation-rule-templates-form.mapper'
import { useStores } from '@storage/hooks/use-stores.hook'
import { observer } from 'mobx-react-lite'
import { cloneElement, ReactElement, useState } from 'react'
import StackDetailsTooltipContainer from './StackDetailsTooltipContainer'

const StackStyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: 0,
  },
}))

interface YardBlockStackTooltipProps {
  children: ReactElement
  stack: StackDto
  maxTier: number
  carrierVisitId: number
  carrierVisitDirection: CarrierVisitDirection
}

export const YardBlockStackTooltip = observer(
  ({
    children,
    stack,
    maxTier,
    carrierVisitId,
    carrierVisitDirection,
  }: YardBlockStackTooltipProps) => {
    const { stackDetailsTooltipContainerUIStore } = useStores()
    const [isTooltipOpen, setIsTooltipOpen] = useState(false)

    const stackSlotDetails = stackDetailsTooltipContainerUIStore.stackSlotDetails(
      stack.blockId,
      stack.bayId,
      stack.rowId,
      maxTier,
      carrierVisitId,
      carrierVisitDirection,
    )

    const handleTooltipToggle = () => {
      setIsTooltipOpen(!isTooltipOpen)
    }

    const childrenWithClick = cloneElement(children, {
      onClick: () => handleTooltipToggle(),
      style: {
        ...children.props.style,
        cursor: 'pointer',
        userSelect: 'none',
      },
    })

    return (
      <StackStyledTooltip
        open={isTooltipOpen}
        onClose={() => setIsTooltipOpen(false)}
        title={
          <StackDetailsTooltipContainer
            stackPosition={
              mapYardPositionDtoToYardPositionDescriptor([{ destination: stack.yardPosition }])[0]
            }
            stackSlotDetails={stackSlotDetails}
          />
        }
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -14],
                },
              },
            ],
          },
        }}
        arrow
      >
        {childrenWithClick}
      </StackStyledTooltip>
    )
  },
)
