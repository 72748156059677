import { Autocomplete, Grid, Skeleton, TextField } from '@mui/material'
import { Stack } from '@mui/system'
import { YardPositionDto } from '@storage/app/api'
import { SelectOption } from '@storage/app/models'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { PositionRangeAutocomplete, useMinimalsTheme } from '@tom-ui/ui'
import { ContainerMovementInfo, useAsyncFetch } from '@tom-ui/utils'
import Item from 'antd/es/descriptions/Item'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import { PositionField, YardPositionSelectorStore } from './stores/yard-position-selector.store'

interface Props {
  containers?: ContainerMovementInfo[]
  handleChange: (position: YardPositionDto, isDangerousYardBlock: boolean) => void
  showTier?: boolean
  gridDirection?: 'row' | 'column'
  position?: YardPositionDto
}

export const YardPositionSelector = observer(
  ({ containers, handleChange, gridDirection, position, showTier = true }: Props) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    const { yardBlockStackStore, yardBlockStore, alertStore } = useStores()

    const positionSelectorStore = useMemo(
      () => new YardPositionSelectorStore(yardBlockStackStore, yardBlockStore),
      [yardBlockStackStore, yardBlockStore],
    )

    useEffect(() => {
      if (position) {
        if (position.block && position.block !== '') {
          const block = positionSelectorStore.yardBlocks.find(y => y.label === position.block)
          if (block) {
            positionSelectorStore.setSelectedYardBlockId(block.value)
            positionSelectorStore.setNewPositionField(PositionField.Block, position?.block ?? '')
          }
        }
        if (position.bay && position.bay !== '') {
          positionSelectorStore.setNewPositionField(PositionField.Bay, position.bay)
        }
      }
    }, [position, positionSelectorStore])

    const { error, loading } = useAsyncFetch(async () => {
      if (yardBlockStore.entries.length === 0) {
        await yardBlockStore.loadList()
      }
      positionSelectorStore.loadStacks()
    }, [yardBlockStore, positionSelectorStore])

    const handleYardBlockChange = (e: React.SyntheticEvent, value?: SelectOption | null) => {
      positionSelectorStore.setSelectedYardBlockId(value?.value)
      handlePositionFieldChange(PositionField.Block, value?.label)
    }

    const handlePositionFieldChange = (field: PositionField, value?: string | null) => {
      positionSelectorStore.setNewPositionField(field, value ?? undefined)
      handleChange(
        positionSelectorStore.newPosition,
        positionSelectorStore.isSelectedBlockDangerous,
      )
    }

    if (loading) {
      return <Skeleton variant='rectangular' width={210} height={60} />
    }

    if (error) {
      alertStore.showAlert(
        'error while loading yard blocks and stacks',
        t(
          'stackingValidationStackingOnTopPlan',
          `We can't plan at this position. There is a stacking rule that prohibits stacking on top`,
        ),
      )
      return
    }

    return (
      <Stack gap={theme.customSpacing.s}>
        <Grid container spacing={theme.customSpacing.xs} direction={gridDirection ?? 'row'}>
          <Grid item xs={4}>
            <Item>
              <Autocomplete
                options={positionSelectorStore.yardBlocks}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t('yardBlock', 'Yard block')}
                    data-cy='allocation-destination-block'
                  />
                )}
                onChange={handleYardBlockChange}
                fullWidth
                value={positionSelectorStore.yardBlocks.find(y => y.label === position?.block)}
              />
            </Item>
          </Grid>
          <Grid item xs={3}>
            <Item>
              <PositionRangeAutocomplete
                label={t('bay', 'Bay')}
                options={positionSelectorStore.bays}
                handleChange={value => handlePositionFieldChange(PositionField.Bay, value)}
                value={position?.bay?.split('-') ?? []}
              />
            </Item>
          </Grid>
          <Grid item xs={3}>
            <Item>
              <PositionRangeAutocomplete
                label={t('row', 'Row')}
                options={positionSelectorStore.rows}
                handleChange={value => handlePositionFieldChange(PositionField.Row, value)}
                value={position?.row?.split('-') ?? []}
              />
            </Item>
          </Grid>
          {showTier && (
            <Grid item xs={2}>
              <Item>
                <Autocomplete
                  options={positionSelectorStore.tiers}
                  renderInput={params => <TextField {...params} label={t('tier', 'Tier')} />}
                  onChange={(e, value) => handlePositionFieldChange(PositionField.Tier, value)}
                  fullWidth
                  disabled={containers && containers.length > 1}
                />
              </Item>
            </Grid>
          )}
        </Grid>
      </Stack>
    )
  },
)
