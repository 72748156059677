import {
  CarrierType,
  CarrierVisitDirection,
  ContainerDamageStatus,
  ContainerJourneyResponseDto,
  ContainerResponseDto,
  CoolingOrderResponseDto,
  OrderResponseDto,
  OrderStatus,
  StrippingOrderResponseDto,
  StuffingOrderResponseDto,
} from '@planning/app/api'
import { IListStore } from '@planning/components/list/SimpleListStore'
import { IEvent, IMessageBus } from '@planning/messages'
import { EventTypes } from '@planning/messages/eventsTypes'
import { ContainerVisitStatus } from '@planning/pages/Order/components/OrdersListCard'
import { IOrderWithVisit } from '@planning/pages/Order/stores/SelectOrderViewStore'
import {
  containerService,
  orderService,
  railVisitService,
  serviceOrdersService,
  truckVisitService,
  vesselVisitService,
} from '@planning/services'
import containerJourneysService from '@planning/services/containerJourneys'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { ContainerJourneyNonNumericFilter } from '../Models/NonNumeric.model'

export interface ContainerJourney extends ContainerJourneyResponseDto {
  container: ContainerResponseDto
  isDamagedContainer: boolean
  inboundOrder?: IOrderWithVisit
  outboundOrder?: IOrderWithVisit
  strippingOrder?: StrippingOrderResponseDto
  stuffingOrder?: StuffingOrderResponseDto
  coolingOrder?: CoolingOrderResponseDto
  status: ContainerVisitStatus
}
export interface IContainerJourneyQueryParameters {
  containerId: number
  activeOrderIds: number[]
  includeCompleted?: boolean
  includeUnlinkedOutboundOrders?: boolean
}

export interface IGroupedContainerJourney {
  containerId: number
  linkedContainerJourneys: ContainerJourney[]
}

export class ContainerJourneyDataStore implements IListStore<ContainerJourney> {
  items: ContainerJourney[] = []
  filter = ''
  isLoading = false
  isNoMatches = false

  constructor(messageBus: IMessageBus) {
    makeObservable(this, {
      items: observable,
      filter: observable,
      isLoading: observable,
      isNoMatches: observable,
      groupedContainerJourneys: computed,
      setFilter: action,
      setLoading: action,
    })

    messageBus.subscribeEvent(EventTypes.OrdersUpsertedEvent, this.receiveOrdersUpsertedMessage)
    messageBus.subscribeEvent(EventTypes.OrdersDeletedEvent, this.receiveOrdersDeletedMessage)
  }

  receiveOrdersUpsertedMessage = async (event: IEvent<OrderResponseDto[]>): Promise<void> => {
    if (event.payload) {
      console.log('ContainerJourneyDataStore receiveOrdersUpsertedMessage', event.payload)

      const orders = event.payload
      const orderIds = orders.map(o => o.id)
      const linkedOrderIds = orders.map(o => o.linkedOrderId)

      const updateRequests = this.items.map(journey =>
        this.updateJourneyOrders(journey, orderIds, orders, linkedOrderIds),
      )

      await Promise.all(updateRequests)
    }
  }

  receiveOrdersDeletedMessage = (event: IEvent<number[]>): void => {
    if (event.payload) {
      console.log('ContainerJourneyDataStore receiveOrdersDeletedMessage', event.payload)

      const orderIds = event.payload

      runInAction(() => {
        this.items.forEach(async journey => {
          const { inboundOrderId, outboundOrderId } = journey
          if (orderIds.includes(inboundOrderId)) {
            runInAction(() => {
              journey.inboundOrderId = -1
              journey.inboundOrder = undefined
            })
          }
          if (outboundOrderId && orderIds.includes(outboundOrderId)) {
            runInAction(() => {
              journey.outboundOrderId = undefined
              journey.outboundOrder = undefined
            })
          }
        })
      })
    }
  }

  setLoading = (isLoading: boolean) => {
    this.isLoading = isLoading
  }

  setFilter = (filter: string) => {
    if (filter !== '') {
      this.items = []
      this.isNoMatches = false
    }
    this.filter = filter
  }

  fetch = async (query: IContainerJourneyQueryParameters) => {
    this.setLoading(true)

    const { containerId, includeCompleted, activeOrderIds, includeUnlinkedOutboundOrders } = query
    const journeyDtos = await containerJourneysService.get(containerId, includeCompleted)

    // TODO: We should only fetch the outbound orders
    const activeOrders: OrderResponseDto[] = activeOrderIds.length
      ? await orderService.getByIds(activeOrderIds)
      : []

    const unlinkedOutboundOrders = includeUnlinkedOutboundOrders
      ? activeOrders.filter(o => o.direction === CarrierVisitDirection.Outbound && !o.linkedOrderId)
      : []

    if (!journeyDtos.length && !activeOrders.length) {
      this.isNoMatches = true
      return
    }

    const journeyDtosForUnlinkedOutbounds = unlinkedOutboundOrders.length
      ? unlinkedOutboundOrders.map(outbound => {
          const journey: ContainerJourneyResponseDto = {
            // TODO: work around - do it properly
            id: -1,
            inboundOrderId: -1,
            containerId: outbound.containerId ?? 0,
            outboundOrderId: outbound.id,
          }
          return journey
        })
      : []

    const journeys = await this.fetchAdjacentData(
      journeyDtos.concat(journeyDtosForUnlinkedOutbounds),
    )

    runInAction(() => {
      this.items = journeys
      this.isNoMatches = false
    })

    this.setLoading(false)
  }

  fetchByContainerNumbers = async (containerNumbers: string[]) => {
    this.setLoading(true)

    const journeyDtos = await containerJourneysService.getByContainerNumbers(containerNumbers)

    if (!journeyDtos.length) {
      this.isNoMatches = true
      return
    }

    const journeys = await this.fetchAdjacentData(journeyDtos)

    runInAction(() => {
      this.items = journeys
      this.isNoMatches = false
    })

    this.setLoading(false)
  }

  public async updateJourneyOrders(
    journey: ContainerJourney,
    orderIds: number[],
    orders: OrderResponseDto[],
    linkedOrderIds: (number | null | undefined)[],
  ) {
    const { inboundOrderId, outboundOrderId } = journey

    // update inbound
    if (orderIds.includes(inboundOrderId)) {
      const inbound = orders.find(o => o.id === inboundOrderId)

      if (inbound) {
        const visitWithOrder = await this.fetchVisitForOrder(inbound)

        runInAction(() => {
          journey.inboundOrder = visitWithOrder
        })
      }
    } else if (outboundOrderId !== null && linkedOrderIds.includes(outboundOrderId)) {
      const inbound = orders.find(o => o.linkedOrderId === outboundOrderId)
      journey.inboundOrderId = inbound?.id ?? -1

      if (inbound) {
        const visitWithOrder = await this.fetchVisitForOrder(inbound)
        runInAction(() => {
          journey.inboundOrder = visitWithOrder
        })
      }
    }

    // update outbound
    if (outboundOrderId && orderIds.includes(outboundOrderId)) {
      const outbound = orders.find(o => o.id === outboundOrderId)

      if (outbound) {
        const visitWithOrder = await this.fetchVisitForOrder(outbound)
        runInAction(() => {
          journey.outboundOrder = visitWithOrder
        })
      }
    } else if (inboundOrderId !== null && linkedOrderIds.includes(inboundOrderId)) {
      const outbound = orders.find(o => o.linkedOrderId === inboundOrderId)
      journey.outboundOrderId = outbound?.id

      if (outbound) {
        const visitWithOrder = await this.fetchVisitForOrder(outbound)
        runInAction(() => {
          journey.outboundOrder = visitWithOrder
        })
      }
    }
  }

  get groupedContainerJourneys() {
    const containerJourneys: IGroupedContainerJourney[] = []

    this.items.forEach(item => {
      const containerId = item.containerId
      const containerJourney = containerJourneys.find(cj => cj.containerId === containerId)

      if (!containerJourney) {
        containerJourneys.push({
          containerId,
          linkedContainerJourneys: [item],
        })
      } else {
        containerJourney.linkedContainerJourneys.push(item)
      }
    })

    containerJourneys.forEach(cj => {
      cj.linkedContainerJourneys.sort((a, b) => {
        const ataA = a.inboundOrder?.visit?.ata
        const ataB = b.inboundOrder?.visit?.ata

        if (ataA === null || ataA === undefined) return -1
        if (ataB === null || ataB === undefined) return 1

        return new Date(ataB).getTime() - new Date(ataA).getTime()
      })
    })

    return containerJourneys
  }

  fetchByContainerAttributes = async (filter: ContainerJourneyNonNumericFilter) => {
    const journeyDtos = await containerJourneysService.getByNonNumeric(filter)

    if (!journeyDtos.length) return

    const journeys = await this.fetchAdjacentData(journeyDtos)
    runInAction(() => {
      this.items = journeys
    })
  }

  get alreadyFetchByOrderIds() {
    return this.items
      .map(j => j.inboundOrderId)
      .concat(
        this.items
          .map(j => j.outboundOrderId)
          .filter((id): id is number => id !== null && id !== undefined),
      )
  }

  fetchByOrderIds = async (orderIds?: Array<number>) => {
    const notFetchedOrderIds = (orderIds ?? []).filter(
      i => !this.alreadyFetchByOrderIds.includes(i),
    )

    if (notFetchedOrderIds.length === 0) return
    const journeyDtos = await containerJourneysService.getByOrderIds(notFetchedOrderIds)

    const outboundOrderIdsHasJourney = journeyDtos.map(j => j.outboundOrderId)
    const outboundOrderIdsHasNoContainerJourney =
      notFetchedOrderIds?.filter(o => !outboundOrderIdsHasJourney.includes(o)) ?? []

    const unlinkedOutboundOrders: OrderResponseDto[] = outboundOrderIdsHasNoContainerJourney.length
      ? (await orderService.getByIds(outboundOrderIdsHasNoContainerJourney)).filter(
          o => o.direction === CarrierVisitDirection.Outbound,
        )
      : []

    if (!journeyDtos.length && !unlinkedOutboundOrders.length) {
      this.isNoMatches = true
      return
    }

    const journeyDtosForUnlinkedOutboundOrders = unlinkedOutboundOrders.length
      ? unlinkedOutboundOrders.map(outbound => {
          const journey: ContainerJourneyResponseDto = {
            // TODO: work around - do it properly
            id: -1,
            inboundOrderId: -1,
            containerId: outbound.containerId ?? 0,
            outboundOrderId: outbound.id,
          }
          return journey
        })
      : []

    const journeys = await this.fetchAdjacentData(
      journeyDtos.concat(journeyDtosForUnlinkedOutboundOrders),
    )

    runInAction(() => {
      this.items = [
        ...this.items.filter(
          i =>
            (i.outboundOrderId && this.alreadyFetchByOrderIds.includes(i.outboundOrderId)) ||
            this.alreadyFetchByOrderIds.includes(i.inboundOrderId),
        ),
        ...journeys,
      ]
      this.isNoMatches = false
    })
  }

  fetchAdjacentData = async (journeyDtos: ContainerJourneyResponseDto[]) => {
    const containerIds = journeyDtos.map(journey => journey.containerId)
    const containers = await Promise.all(containerIds.map(async id => containerService.getById(id)))
    const fetchedContainerIds = containers.map(c => c.id)

    const orderIds = journeyDtos
      .flatMap(j => [j.inboundOrderId, j.outboundOrderId])
      .filter(id => id && id !== -1)
      .map(id => id!)

    const orders = orderIds.length ? await orderService.getByIds(orderIds) : []

    // [CoolingOrder] TODO: fetch CoolingOrders as well
    const strippingOrderIds = journeyDtos
      .map(j => j.strippingOrderId)
      .filter(id => id)
      .map(id => id!)

    const strippingOrders = strippingOrderIds.length
      ? await serviceOrdersService.getStrippingOrdersByIds(strippingOrderIds)
      : []

    const stuffingOrderIds = journeyDtos
      .map(j => j.stuffingOrderId)
      .filter(id => id)
      .map(id => id!)

    const stuffingOrders = stuffingOrderIds.length
      ? await serviceOrdersService.getStuffingOrdersByIds(stuffingOrderIds)
      : []

    const coolingOrderIds = journeyDtos
      .map(j => j.coolingOrderId)
      .filter(id => id)
      .map(id => id!)

    const coolingOrders = coolingOrderIds.length
      ? await serviceOrdersService.getCoolingOrdersByIds(coolingOrderIds)
      : []

    const ordersWithVisit = orders.length
      ? await Promise.all(orders.map(async o => await this.fetchVisitForOrder(o)))
      : []

    const journeys = journeyDtos
      .filter(j => fetchedContainerIds.includes(j.containerId))
      .map(journeyDto => {
        // TODO: Use dictionaries to avoid nested looping
        const inbound = ordersWithVisit.find(o => o.order!.id === journeyDto.inboundOrderId)
        const outbound = ordersWithVisit.find(o => o.order!.id === journeyDto.outboundOrderId)
        const container = containers.find(c => c.id === journeyDto.containerId)!
        const outJourney: ContainerJourney = {
          ...journeyDto,
          container: container,
          isDamagedContainer: container.damages.some(
            c => c.status === ContainerDamageStatus.Active,
          ),
          inboundOrder: inbound,
          outboundOrder: outbound,
          strippingOrder: strippingOrders.find(so => so.id === journeyDto.strippingOrderId),
          stuffingOrder: stuffingOrders.find(so => so.id === journeyDto.stuffingOrderId),
          coolingOrder: coolingOrders.find(so => so.id === journeyDto.coolingOrderId),
          status: this.getContainerVisitStatus(inbound?.order?.status, outbound?.order?.status),
        }

        return outJourney
      })

    return journeys
  }

  // TODO: fetch all carrierVisits at once and assign to the orders
  fetchVisitForOrder = async (order: OrderResponseDto): Promise<IOrderWithVisit> => {
    const carrierVisitId = order.carrierVisitId
    if (!carrierVisitId) return { order }

    if (order.carrierVisitType === CarrierType.Vessel) {
      const { data: vesselVisits } = await vesselVisitService.getByIds([carrierVisitId])
      return { order, visit: vesselVisits[0] }
    }

    if (order.carrierVisitType === CarrierType.Train) {
      const railVisits = await railVisitService.getByIds([carrierVisitId])
      return { order, visit: railVisits[0] }
    }

    if (order.carrierVisitType === CarrierType.Truck) {
      const { data: truckVisits } = await truckVisitService.getByIds([carrierVisitId])
      return { order, visit: truckVisits[0] }
    }

    return { order }
  }

  reset = () => {
    this.items = []
  }

  getContainerVisitStatus = (inboundStatus?: OrderStatus, outboundStatus?: OrderStatus) => {
    if (outboundStatus === OrderStatus.Fulfilled) return ContainerVisitStatus.Departed

    if (inboundStatus !== OrderStatus.Fulfilled) return ContainerVisitStatus.Expected

    return ContainerVisitStatus.OnTerminal
  }
}
