import { TextField } from '@mui/material'
import React from 'react'
import { Control, Controller } from 'react-hook-form'

interface IProps {
  name: string
  control: Control<any>
}

export const NotesField: React.FC<IProps> = ({ name, control }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField {...field} label='Notes' multiline rows={3} variant='outlined' fullWidth />
      )}
    />
  )
}
