import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material'
import {
  IssueType,
  OrderIssueResolutionType,
  ResolveOrderIssueCommand,
  TransportData,
} from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { ContainerJourney } from '@planning/pages/ServiceOrders/Stores/ContainerJourneyDataStore'
import { issueService } from '@planning/services'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { IssueResolutionViewStore } from '../../Stores/IssueResolutionViewStore'
import { requiredTransportDataResolution } from '../../Stores/IssuesViewStore'
import { NotesField } from '../atoms/NotesField'
import { ResolveIssueCard } from '../molecules/ResolveIssueCard'
import { ResolveIssuesFormList } from '../organisms/ResolveIssuesFormList'

interface Props {
  store: IssueResolutionViewStore
}

export interface ResolveIssuesFormData {
  notes: string
}

function getAlertMessage(issueType?: IssueType, data?: ContainerJourney, railcarName?: string) {
  if (!data) return

  const order = data.inboundOrder?.order ?? data.outboundOrder?.order

  if (issueType === IssueType.MissingRailcar) {
    return `Railcar ${railcarName ?? ''} is missing - Container ${order?.containerNumber} needs to be replanned for loading.`
  }
}

export const ResolveIssueDialog = observer(({ store }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { appViewStore, railcarItemStore } = usePlanningStore()

  const title = t('resolveIssue', 'Resolve Issue')

  const { control, handleSubmit, reset } = useForm<ResolveIssuesFormData>({
    defaultValues: {},
  })

  useEffect(() => {
    reset({
      notes: store.issueToBeResolved?.data.notes ?? '',
    })
  }, [store.issueToBeResolved, reset])

  const handleDialogClose = () => {
    store.closeDialog()
  }

  const onSubmit = async (data: ResolveIssuesFormData) => {
    if (!store.validate() || !store.issueToBeResolved || !store.selectedIssueResolution) {
      store.setShowErrors(true)
      return
    } else store.setShowErrors(false)

    const resolutionType = store.selectedIssueResolution
    const issueId = store.issueToBeResolved.id

    const cmd: ResolveOrderIssueCommand = {
      ...data,
      issueId,
      resolutionType,
      transportData: requiredTransportDataResolution.includes(resolutionType)
        ? (store.resolutionData() as TransportData)
        : undefined,
    }

    try {
      await issueService.resolveOrderIssue(cmd)

      appViewStore.setShowAlert(
        'success',
        resolutionType === OrderIssueResolutionType.FollowUp
          ? t('issueHasBeenMarkedForFollowUp', 'Issue has been marked for follow up')
          : t('issueHasBeenResolved', 'Issue has been resolved'),
      )

      reset()
      store.setShowErrors(false)
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToResolveIssue', 'Failed to resolve issue'))
    }

    handleDialogClose()
  }

  const order =
    store.issueToBeResolved?.containerJourney?.inboundOrder?.order ??
    store.issueToBeResolved?.containerJourney?.outboundOrder?.order
  const railcarId = order?.railcarId ?? ''
  const railcarName = _(railcarItemStore.elements).find(r => r.data.id === railcarId)?.data.name
  const alertMessage = getAlertMessage(
    store.issueToBeResolved?.data.issueType,
    store.issueToBeResolved?.containerJourney,
    railcarName,
  )
  return (
    <Dialog fullWidth maxWidth='lg' open={store.isOpen} onClose={handleDialogClose}>
      <DialogTitle sx={{ borderBottom: `1px solid ${theme.palette.grey[300]}`, mb: '1rem' }}>
        {title}
      </DialogTitle>

      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Box
            sx={{
              borderBottom: `1px solid ${theme.palette.grey[300]}`,
              paddingBottom: '1.5rem',
              mb: '1rem',
            }}
          >
            <ResolveIssueCard issue={store.issueToBeResolved} />
          </Box>

          {alertMessage && (
            <Alert severity='warning' sx={{ mb: '2rem' }}>
              {alertMessage}
            </Alert>
          )}

          <ResolveIssuesFormList store={store} />

          <NotesField name='notes' control={control} />
        </DialogContent>

        <DialogActions
          sx={{
            mt: theme.customSpacing.l,
            display: 'flex',
            justifyContent: 'space-between',
            borderTop: `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          <Stack direction={'row'} spacing={2}>
            <Button type='submit' variant='contained' color='primary'>
              {t('confirm', 'Confirm')}
            </Button>
            <Button onClick={handleDialogClose} color='secondary'>
              {t('cancel', 'Cancel')}
            </Button>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  )
})
