import { Permission } from '@storage/app/models'
import { PermissionGuard } from '@storage/components/PermissionGuard'
import ContainerTurnovers from '@storage/pages/container-turnovers/ContainerTurnovers'
import GeneralCargoInventoryContainer from '@storage/pages/general-cargo-inventory/GeneralCargoInventory'
import OperatorContainerTurnoverPositionUpdate from '@storage/pages/operator-container-turnovers-list/OperatorContainerTurnoverPositionUpdate'
import OperatorContainerTurnoversList from '@storage/pages/operator-container-turnovers-list/OperatorContainerTurnoversList'
import YardManagement from '@storage/pages/yard-management/YardManagement'
import YardPlanningDashboardDetails from '@storage/pages/yard-planning-dashboard-details/YardPlanningDashboardDetails'
import YardPlanningDashboard from '@storage/pages/yard-planning-dashboard/YardPlanningDashboard'
import YardInconsistencies from '@storage/pages/yard-planning-issues/YardInconsistencies'
import YardPlanningIssues from '@storage/pages/yard-planning-issues/YardPlanningIssues'
import YardSetup from '@storage/pages/yard-setup/YardSetup'
import { createBrowserHistory } from 'history'
import { Navigate, RouteObject, useRoutes } from 'react-router-dom'
import { BerthsPage, NotFoundPage, PiersPage } from './elements'
import { PATH_STORAGE } from './paths'

export const initialRoute = PATH_STORAGE.yardManagement

export const browserHistory = createBrowserHistory()

export default function Router() {
  const routes: RouteObject[] = [
    {
      path: '/',
      element: <Navigate to={initialRoute} replace />,
    },
    {
      path: PATH_STORAGE.yardManagement,
      element: <YardManagement />,
    },
    {
      path: PATH_STORAGE.layout.yardSetup,
      element: <YardSetup />,
    },
    {
      path: PATH_STORAGE.containerTurnovers,
      element: <ContainerTurnovers />,
    },
    {
      path: PATH_STORAGE.layout.berths,
      element: (
        <PermissionGuard component={BerthsPage} requiredPermission={Permission.ReadBerths} />
      ),
    },
    {
      path: PATH_STORAGE.layout.piers,
      element: <PermissionGuard component={PiersPage} requiredPermission={Permission.ReadPiers} />,
    },

    {
      path: PATH_STORAGE.yardPlanningDashboard.root,
      element: <YardPlanningDashboard />,
    },
    {
      path: PATH_STORAGE.yardPlanningDashboard.details.root,
      element: <YardPlanningDashboardDetails />,
    },
    {
      path: PATH_STORAGE.yardPlanningIssues,
      element: <YardPlanningIssues />,
    },
    {
      path: PATH_STORAGE.yardInconsistencies,
      element: <YardInconsistencies />,
    },
    {
      path: PATH_STORAGE.operatorContainerTurnoversList.root,
      element: <OperatorContainerTurnoversList />,
    },
    {
      path: PATH_STORAGE.operatorContainerTurnoversList.positionUpdate.root,
      element: <OperatorContainerTurnoverPositionUpdate />,
    },
    {
      path: PATH_STORAGE.generalCargoInventory,
      element: <GeneralCargoInventoryContainer />,
    },
    {
      path: '*',
      children: [
        { path: PATH_STORAGE.notFound, element: <NotFoundPage /> },
        { path: '*', element: <Navigate to='/404' replace /> },
      ],
    },
    { path: '*', element: <Navigate to='/404' replace /> },
  ]

  return useRoutes(routes)
}

export * from './paths'
