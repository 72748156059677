import { Box, Container, Typography } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { InitializationWrapper } from '@planning/components'
import { useTranslate } from '@tolgee/react'
import { NoListResult, useMinimalsTheme } from '@tom-ui/ui'
import { useAsyncFetch } from '@tom-ui/utils'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useLocation } from 'react-router'
import { IssueCard } from './Components/molecules/IssueCard'
import { IssuesFilterChips } from './Components/molecules/IssuesFilterChips'
import { IssuesTypeFilter } from './Components/molecules/IssuesTypeFilter'
import { IssuesPageHeader } from './Components/organisms/IssuesPageHeader'
import { ResolveIssueDialog } from './Components/templates/ResolveIssueDialog'
import { IssueResolutionViewStore } from './Stores/IssueResolutionViewStore'
import { IssuesViewStore } from './Stores/IssuesViewStore'

export type IssuesPageLocationState = {
  filterByRailCarTrackPositionId?: number
  visitId?: number
}

const useCreateViewStores = () => {
  const {
    issueItemStore,
    containerJourneyDataStore,
    railVisitItemStore,
    railcarTrackPositionItemStore,
  } = usePlanningStore()
  const issuesViewStore = useLocalObservable(() => {
    return new IssuesViewStore(
      issueItemStore,
      containerJourneyDataStore,
      railVisitItemStore,
      railcarTrackPositionItemStore,
    )
  })
  const issueResolutionViewStore = useLocalObservable(() => {
    return new IssueResolutionViewStore()
  })

  return { issuesViewStore, issueResolutionViewStore }
}

export const IssuesPage = observer(() => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()
  const { state } = useLocation() as { state: IssuesPageLocationState }

  const { railcarItemStore } = usePlanningStore()
  const { issuesViewStore, issueResolutionViewStore } = useCreateViewStores()

  useEffect(() => {
    if (state?.filterByRailCarTrackPositionId) {
      issuesViewStore.setFilterByRailCarTrackPositionId(state?.filterByRailCarTrackPositionId)
    } else if (state?.visitId) {
      issuesViewStore.setFilterByVisitId(state?.visitId)
    }
  }, [issuesViewStore, state])

  const { loading, error } = useAsyncFetch(async () => {
    issuesViewStore.setLoadContainerJourneyDataReaction(true)
    await issuesViewStore.fetch()
  }, [issuesViewStore])

  const { loading: railLoading, error: railErrors } = useAsyncFetch(async () => {
    return railcarItemStore.fetch()
  }, [railcarItemStore])

  if (error || railErrors) {
    return <Typography variant='h4'>{t('errorLoadingIssues', 'Error loading issues')}</Typography>
  }

  return (
    <InitializationWrapper
      isInitializing={loading || railLoading || !issuesViewStore.dataFilteredByIssueStatus}
    >
      <Box
        sx={{
          height: '100%',
        }}
      >
        <IssuesPageHeader store={issuesViewStore} />
        <IssuesFilterChips store={issuesViewStore} />
        <IssuesTypeFilter store={issuesViewStore} />

        <Container
          sx={{
            paddingY: theme.customSpacing.m,
          }}
        >
          {issuesViewStore.dataFilteredByIssueStatus.length === 0 ? (
            <NoListResult
              result={t('noIssuesFound', 'No issues found')}
              filtering={issuesViewStore.isFilterEnabled || !!issuesViewStore.containerNumberFilter}
              onClearFilter={() => issuesViewStore.clearFilters()}
            />
          ) : (
            issuesViewStore.dataFilteredByIssueStatus.map(i => (
              <IssueCard
                key={i.id}
                issue={i.data}
                containerVisit={i.containerJourney}
                onClickResolve={() => {
                  issueResolutionViewStore.openDialog(i)
                }}
              />
            ))
          )}
        </Container>

        <ResolveIssueDialog store={issueResolutionViewStore} />
      </Box>
    </InitializationWrapper>
  )
})
