import {
  AllowOrderPassageCommand,
  CarrierType,
  CarrierVisitDirection,
  ConfirmActualCargoAmountOrderCommand,
  CreateOrAssignOrderToRailcarTrackPositionCommand,
  CreateOrdersCommand,
  GetFirstAvailableOrderInGroupQueryItem,
  MarkOrderUpdatesAsReadCommand,
  OrderStatus,
  OrdersApi,
  ReportOrderShortlandedCommand,
  UpdateCustomerSequenceCommand,
  UpdateOrderCommand,
  UpdateOrderWeightCommand,
  UpdateReleaseOrdersCommand,
} from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'

class OrderService {
  httpClient = createApiClient(OrdersApi)

  update = async (cmd: UpdateOrderCommand) => await this.httpClient.put(cmd)

  allowPassage = async (cmd: AllowOrderPassageCommand) => await this.httpClient.allowPassage(cmd)

  /**
   * @deprecated use deleteByIds instead
   */
  delete = async (id: number, deleteLinkedOrders?: boolean) =>
    await this.httpClient._delete(id, deleteLinkedOrders)

  deleteByIds = async (ids: number[], deleteLinkedOrders?: boolean) =>
    await this.httpClient.deleteByIds({
      deleteLinkedOrders: deleteLinkedOrders,
      ids: ids,
    })

  create = async (cmd: CreateOrdersCommand) => await this.httpClient.post(cmd)

  updateReleaseOrders = async (cmd: UpdateReleaseOrdersCommand) =>
    await this.httpClient.updateOrdersReleaseTime(cmd)

  getByContainerNumber = async (filter: string, status?: OrderStatus) => {
    const { data } = await this.httpClient.getByContainerNumber(filter, status)
    return data
  }

  getById = async (filter: number) => {
    const { data } = await this.httpClient.getById(filter)
    return data
  }

  getByIds = async (ids: number[]) => {
    const { data } = await this.httpClient.getByIds(ids)
    return data
  }

  getByReferenceNumber = async (
    filter: string,
    direction: CarrierVisitDirection,
    carrierType?: CarrierType,
    status?: OrderStatus,
  ) => {
    const { data } = await this.httpClient.getByReferenceNumber(
      filter,
      direction,
      carrierType,
      status,
    )
    return data
  }

  dropOffOrders = async (filter?: string) => {
    const { data } = await this.httpClient.dropOffOrders(filter)

    return data
  }

  dropOffOrdersContainerJourney = async (filter?: string) => {
    const { data } = await this.httpClient.containerJourney(filter)

    return data
  }

  pickUpOrders = async (filter?: string, isSearchByReferenceNumber?: boolean) => {
    const { data } = await this.httpClient.pickUpOrders(filter, isSearchByReferenceNumber)

    return data
  }

  firstAvailableOrderInGroups = async (filter?: GetFirstAvailableOrderInGroupQueryItem[]) => {
    const { data } = await this.httpClient.firstAvailableOrderInGroups({ queryItems: filter })

    return data
  }

  updateWeight = async (cmd: UpdateOrderWeightCommand) => {
    await this.httpClient.weight(cmd.id, cmd)
  }

  updateCustomerSequence = async (cmd: UpdateCustomerSequenceCommand) => {
    await this.httpClient.customerSequence(cmd)
  }

  confirmActualCargoAmount = async (cmd: ConfirmActualCargoAmountOrderCommand) => {
    await this.httpClient.actualCargoAmount(cmd.id, cmd)
  }

  createOrAssignOrderToRailcarTrackPosition = async (
    cmd: CreateOrAssignOrderToRailcarTrackPositionCommand,
  ) => {
    const { data } = await this.httpClient.createOrAssignToRailcarTrackPosition(cmd)

    return data
  }

  reportShortlandedContainer = async (cmd: ReportOrderShortlandedCommand) => {
    await this.httpClient.reportShortlandedContainer(cmd)
  }

  markOrderUpdatesAsRead = async (cmd: MarkOrderUpdatesAsReadCommand) => {
    await this.httpClient.markOrderUpdatesAsRead(cmd)
  }
}

const orderService = new OrderService()

export default orderService
