import { Dialog, DialogTitle } from '@mui/material'
import { useOpenFeatureClient } from '@openfeature/react-sdk'
import { usePlanningStore } from '@planning/AppProvider'
import { CarrierType, CarrierVisitDirection, CustomerResponseDto } from '@planning/app/api'
import {
  DuplicateRailcarsWithDifferentRailTracksError,
  DuplicateRailcarsWithDifferentSequenceError,
  DuplicateSequencesWithDifferentRailcarsError,
  FileFormatError,
  InvalidRailtrackError,
  IsoCodeValidationError,
  MissingRailtrackError,
  NotPlannedRailtrackError,
  RailcarSequenceConflict,
} from '@planning/services/orderListParsingService'
import { useTranslate } from '@tolgee/react'
import { CloseIcon, IconButton } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { OrderListUploadDialogBody } from './OrderListUploadDialogBody'
import { OrderListUploadDialogFooter } from './OrderListUploadDialogFooter'
import { OrderListUploadStepper } from './OrderListUploadStepper'

interface IProps {
  open: boolean
  enableFieldSelectionFromOrdersView: boolean
  handleDialogClose: () => Promise<void> | void
  visitType?: CarrierType
}

export interface IOrderListUploadFormData {
  customer?: CustomerResponseDto
  direction: CarrierVisitDirection
}

export const OrderListUploadDialogV2: FC<IProps> = observer(
  ({ open, visitType, enableFieldSelectionFromOrdersView, handleDialogClose }) => {
    const { orderListUploadViewStoreV2, tenantStore } = usePlanningStore()
    const { t } = useTranslate()

    const featureFlagClient = useOpenFeatureClient()
    const skipTallyLoadPrepFF = useMemo(
      () => featureFlagClient.getBooleanValue('skip-tally-load-prep', false),
      [featureFlagClient],
    )

    const {
      handleSubmit,
      register,
      setValue,
      control,
      reset,
      formState: { errors },
    } = useForm({
      defaultValues: {
        customer: undefined,
        direction: CarrierVisitDirection.Inbound,
      } as IOrderListUploadFormData,
    })

    const handleValidateAndSubmit = async (data: IOrderListUploadFormData) => {
      if (!tenantStore.tenant) return

      orderListUploadViewStoreV2.parsingStore.setUploadFailedReason()

      const { parsingStore } = orderListUploadViewStoreV2
      orderListUploadViewStoreV2.setCustomer(data.customer)
      if (tenantStore.tenant.unloCodes.length)
        parsingStore.setUNLOCodes(tenantStore.tenant.unloCodes.map(i => i.name!))
      // [UNLOCodes] TODO: Review
      else parsingStore.setUNLOCodes(['DEHAM'])

      await parseOrderList()

      if (orderListUploadViewStoreV2.parsingStore.uploadFailedReason) return

      orderListUploadViewStoreV2.setActiveStep(1)
    }

    const handleError = (error: Error) => {
      const { message } = error

      if (error instanceof FileFormatError) {
        orderListUploadViewStoreV2.setUploadFailedReason(
          `${t('fileFormatError', 'File format error')}: ${message}`,
        )
        return
      }

      if (error instanceof IsoCodeValidationError) {
        orderListUploadViewStoreV2.setUploadFailedReason(
          t('isoCodeValidationError', 'Found invalid IsoCodes: {isoCodes}', {
            isoCodes: error.isoCodes.join(', '),
          }),
        )
        return
      }

      if (error instanceof MissingRailtrackError) {
        orderListUploadViewStoreV2.setUploadFailedReason(
          t('missingRailtrackError', 'Found railcars with missing rail track: {railcars}', {
            railcars: error.railcars.join(', '),
          }),
        )
        return
      }

      if (error instanceof InvalidRailtrackError) {
        orderListUploadViewStoreV2.setUploadFailedReason(
          t(
            'invalidRailtrackError',
            'The rail track(s) {railTracks} do not exist in the system. Please check your file and the available rail tracks in the system (Rail tracks can be viewed and created in the Layout menu)',
            {
              railTracks: error.railTracks.join(', '),
            },
          ),
        )
        return
      }

      if (error instanceof NotPlannedRailtrackError) {
        orderListUploadViewStoreV2.setUploadFailedReason(
          t(
            'notPlannedRailtrackError',
            'We found rail tracks {railTracks} in your file that have not been assinged to the rail vist. Rail tracks used in your upload must be assigned via rail track planning inside the system beforehand. Please check rail track planning or adjust your file',
            {
              railTracks: error.railTracks.join(', '),
            },
          ),
        )
        return
      }

      if (error instanceof DuplicateRailcarsWithDifferentSequenceError) {
        orderListUploadViewStoreV2.setUploadFailedReason(
          t(
            'duplicatedRailcarsWithDifferentSequenceError',
            'Duplicated railcars with different sequence: {railcars}',
            { railcars: error.railcars.join(', ') },
          ),
        )
        return
      }

      if (error instanceof DuplicateSequencesWithDifferentRailcarsError) {
        orderListUploadViewStoreV2.setUploadFailedReason(
          t(
            'duplicateSequencesWithDifferentRailcarsError',
            'The file you are trying to upload containes several railcars with the same sequence number. Please check lines {lineNumbers} in the csv file',
            { lineNumbers: error.lineNumbers.join(', ') },
          ),
        )
        return
      }

      if (error instanceof DuplicateRailcarsWithDifferentRailTracksError) {
        orderListUploadViewStoreV2.setUploadFailedReason(
          t(
            'duplicateRailcarsWithDifferentRailTracksError',
            'Duplicate railcars with different rail tracks: {railcars}',
            { railcars: error.railcars.join(', ') },
          ),
        )
        return
      }

      if (error instanceof RailcarSequenceConflict) {
        orderListUploadViewStoreV2.setUploadFailedReason(
          t('railcarSequenceConflictDetected', 'Railcar sequence conflict detected: {railcars}', {
            railcars: error.mismatchedRailcars.join(' '),
          }),
        )
        return
      }

      orderListUploadViewStoreV2.setUploadFailedReason(message)
      return
    }

    const parseOrderList = async () => {
      try {
        await orderListUploadViewStoreV2.parseOrderList(skipTallyLoadPrepFF)

        if (!orderListUploadViewStoreV2.parsingStore.file) {
          orderListUploadViewStoreV2.setUploadFailedReason(
            t(
              'noFileAddedForUploadPleaseChooseAFile',
              'No file added for upload, please choose a file',
            ),
          )
          return
        }

        if (!orderListUploadViewStoreV2.dataStore.ordersFromUpdate.length) {
          const { unloCodes } = orderListUploadViewStoreV2.parsingStore
          const operation =
            orderListUploadViewStoreV2.direction === CarrierVisitDirection.Inbound
              ? 'discharge'
              : 'loading'

          const noContainersFoundMessage = `${t(
            'noContainersFoundFor',
            'No containers found for',
          )} ${operation} ${t('atTheConfiguredPortCodes', 'at the configured port codes')} (${unloCodes.join(', ')}).`

          orderListUploadViewStoreV2.setUploadFailedReason(noContainersFoundMessage)
          return
        }
      } catch (error: any) {
        handleError(error)
      }
    }

    return (
      <Dialog
        open={open}
        sx={{ zIndex: '1000' }}
        fullWidth={true}
        maxWidth='md'
        PaperProps={{
          sx: {
            minHeight: '90vh',
            maxHeight: 'none',
          },
        }}
      >
        <DialogTitle>{t('uploadOrders', 'Upload orders')}</DialogTitle>
        <IconButton
          onClick={handleDialogClose}
          sx={{
            position: 'absolute',
            right: '1rem',
            top: '1rem',
          }}
        >
          <CloseIcon />
        </IconButton>
        <OrderListUploadStepper store={orderListUploadViewStoreV2} />
        <form onSubmit={handleSubmit(handleValidateAndSubmit)}>
          <OrderListUploadDialogBody
            visitType={visitType}
            store={orderListUploadViewStoreV2}
            enableVisitSelectField={enableFieldSelectionFromOrdersView}
            register={register}
            errors={errors}
            setValue={setValue}
            control={control}
          />
          <OrderListUploadDialogFooter store={orderListUploadViewStoreV2} resetForm={reset} />
        </form>
      </Dialog>
    )
  },
)
