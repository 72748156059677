import { Box, FormGroup, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { YardBlockDto } from '@storage/app/api'
import { FormProps, FormType } from '@storage/app/models'
import { ControlledSelect } from '@storage/components/form-controls'
import { ControlledMultipleChipSelect } from '@storage/components/form-controls/ControlledMultipleChipSelect'
import { ControlledNumberField } from '@storage/components/form-controls/ControlledNumberField'
import { ControlledTextField } from '@storage/components/form-controls/ControlledTextField'
import useFormWithSchema from '@storage/hooks/use-form-with-schema.hook'
import { useTranslate } from '@tolgee/react'
import { InfoIcon } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { YardBlockPreview } from '../../../yard-management/components/YardBlockPreview'
import { useStackingStrategyOptions } from './hooks/stacking-strategy-options.hook'
import { useEquipmentTypesOptions } from './hooks/use-equipment-types-options.hook'
import { useLabelingPatternOptions } from './hooks/use-labeling-pattern-type-options.hook'
import { useYardBlockUsageOptions } from './hooks/use-yard-block-usage-options.hook'
import { generateLabelRange, getBoundsHelperText } from './utils/yard-block-form.util'
import { YardBlockFormMapper } from './yard-block-form.mapper'
import {
  LabelingPatternType,
  YardBlockFormContext,
  YardBlockFormProfile,
  defaultValues,
} from './yard-block-form.profile'
import { schema } from './yard-block-form.scheme'

interface Props extends FormProps {
  yardBlock?: YardBlockDto
  type: FormType
  onSubmit: (formValues: YardBlockFormProfile) => Promise<void> | void
}

export const YardBlockForm = observer(({ id, yardBlock, type, onSubmit }: Props) => {
  const { handleSubmit, formState, control, reset, watch, setValue, trigger } = useFormWithSchema<
    YardBlockFormProfile,
    YardBlockFormContext
  >(schema, defaultValues, { context: { type } })

  const equipmentTypesOptions = useEquipmentTypesOptions()
  const usageOptions = useYardBlockUsageOptions()
  const [isFormValid, setIsFormValid] = useState(false)
  const [isInitialLoad, setIsInitialLoad] = useState(true)

  const { t } = useTranslate()

  const labelingPatternOptions = useLabelingPatternOptions()
  const stackingStrategiesOptions = useStackingStrategyOptions()

  useEffect(() => {
    if (yardBlock) reset(YardBlockFormMapper.mapDtoToFormValues(yardBlock))
  }, [reset, yardBlock])

  const { baysFrom, baysTo, baysPatternType, rowsFrom, rowsTo, rowsPatternType } = watch()

  useEffect(() => {
    const validate = async () => {
      const valid = await trigger(['baysFrom', 'baysTo', 'rowsFrom', 'rowsTo'])
      setIsFormValid(valid)
    }

    validate()
  }, [trigger, baysFrom, baysTo, baysPatternType, rowsFrom, rowsTo, rowsPatternType])

  const bayNames = useMemo(() => {
    return baysFrom && baysTo && baysPatternType
      ? generateLabelRange(baysFrom, baysTo, baysPatternType)
      : []
  }, [baysFrom, baysPatternType, baysTo])

  const rowNames = useMemo(() => {
    return rowsFrom && rowsTo && rowsPatternType
      ? generateLabelRange(rowsFrom, rowsTo, rowsPatternType)
      : []
  }, [rowsFrom, rowsTo, rowsPatternType])

  useEffect(() => {
    if (rowsPatternType === LabelingPatternType.Alphabetic) {
      setValue('rowsFrom', rowsFrom?.toUpperCase())
      setValue('rowsTo', rowsTo?.toUpperCase())
    }
    if (baysPatternType === LabelingPatternType.Alphabetic) {
      setValue('baysFrom', baysFrom?.toUpperCase())
      setValue('baysTo', baysTo?.toUpperCase())
    }
  }, [rowsFrom, rowsTo, baysFrom, baysTo, rowsPatternType, baysPatternType, setValue])

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (!isInitialLoad) {
        if (name === 'rowsPatternType') {
          setValue('rowsFrom', '')
          setValue('rowsTo', '')
        } else if (name === 'baysPatternType') {
          setValue('baysFrom', '')
          setValue('baysTo', '')
        }
      }
    })

    setIsInitialLoad(false)

    return () => subscription.unsubscribe()
  }, [watch, setValue, isInitialLoad])

  useEffect(() => {
    const limitInputLength = (
      fieldName: keyof YardBlockFormProfile,
      patternType: LabelingPatternType,
      value: string | undefined,
      maxLength: number,
    ) => {
      if (patternType === LabelingPatternType.Numeric && value && value.length > maxLength) {
        setValue(fieldName, value?.slice(0, maxLength))
      }
    }
    const subscription = watch((value, { name }) => {
      switch (name) {
        case 'rowsFrom':
          limitInputLength('rowsFrom', rowsPatternType!, value.rowsFrom, 3)
          break
        case 'rowsTo':
          limitInputLength('rowsTo', rowsPatternType!, value.rowsTo, 3)
          break
        case 'baysFrom':
          limitInputLength('baysFrom', baysPatternType!, value.baysFrom, 3)
          break
        case 'baysTo':
          limitInputLength('baysTo', baysPatternType!, value.baysTo, 3)
          break
        default:
          break
      }
    })

    return () => subscription.unsubscribe()
  }, [watch, setValue, rowsPatternType, baysPatternType])

  return (
    <Box
      id={id}
      component='form'
      autoComplete='off'
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      my={1}
    >
      <FormGroup sx={{ gap: '1rem' }}>
        <ControlledTextField<YardBlockFormProfile>
          name='name'
          formState={formState}
          control={control}
          required
          label={t('name', 'Name')}
          autoFocus
          fullWidth
          endAdornment={
            <Tooltip
              title={t(
                'blockNameFieldHelpTooltip',
                'Please enter the name using numbers and letters. Special characters, spacing are not allowed. The max number of characters is 10.',
              )}
            >
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          }
        />

        <ControlledNumberField<YardBlockFormProfile>
          name='maxTier'
          formState={formState}
          control={control}
          label={t('Max tier', 'Max tier')}
          fullWidth
        />

        {type === 'Add' && (
          <>
            <Typography variant='subtitle1'>{t('rowsPattern', 'Rows labeling pattern')}</Typography>

            <ControlledSelect
              options={labelingPatternOptions}
              formState={formState}
              name='rowsPatternType'
              control={control}
              label={t('patternType', 'Pattern Type')}
            />

            <Box sx={{ display: 'flex', gap: 1 }}>
              <ControlledTextField
                name='rowsFrom'
                required
                type={rowsPatternType === LabelingPatternType.Numeric ? 'number' : 'text'}
                maxLength={rowsPatternType === LabelingPatternType.Alphabetic ? 1 : undefined}
                helperText={getBoundsHelperText(rowsPatternType!)}
                formState={formState}
                control={control}
                fullWidth
                label={t('from', 'From')}
              />
              <ControlledTextField
                name='rowsTo'
                required
                type={rowsPatternType === LabelingPatternType.Numeric ? 'number' : 'text'}
                maxLength={rowsPatternType === LabelingPatternType.Alphabetic ? 1 : undefined}
                helperText={getBoundsHelperText(rowsPatternType!)}
                formState={formState}
                control={control}
                fullWidth
                label={t('to', 'To')}
              />
            </Box>

            <Typography variant='subtitle1'>{t('baysPattern', 'Bays labeling pattern')}</Typography>

            <ControlledSelect
              options={labelingPatternOptions}
              formState={formState}
              name='baysPatternType'
              control={control}
              label={t('patternType', 'Pattern Type')}
            />

            <Box sx={{ display: 'flex', gap: 1 }}>
              <ControlledTextField
                name='baysFrom'
                required
                type={baysPatternType === LabelingPatternType.Numeric ? 'number' : 'text'}
                maxLength={baysPatternType === LabelingPatternType.Alphabetic ? 1 : undefined}
                helperText={getBoundsHelperText(baysPatternType!)}
                formState={formState}
                control={control}
                fullWidth
                label={t('from', 'From')}
              />
              <ControlledTextField
                name='baysTo'
                required
                type={baysPatternType === LabelingPatternType.Numeric ? 'number' : 'text'}
                maxLength={baysPatternType === LabelingPatternType.Alphabetic ? 1 : undefined}
                helperText={getBoundsHelperText(baysPatternType!)}
                formState={formState}
                control={control}
                fullWidth
                label={t('to', 'To')}
              />
            </Box>

            <Typography variant='subtitle1'>{t('yardBlockPreview', 'Preview')}</Typography>

            <Typography variant='body2'>
              {isFormValid
                ? t(
                    'yardBlockPreviewDescription',
                    'This is a preview of the labeling patterns and values selected above',
                  )
                : t(
                    'yardBlockPreviewValidation',
                    'Please fill in the relevant fields above to generate the block preview',
                  )}
            </Typography>

            {isFormValid && <YardBlockPreview bayNames={bayNames} rowNames={rowNames} />}
          </>
        )}

        <Typography variant='subtitle1'>{t('stackingStrategy', 'Stacking Strategy')}</Typography>

        <ControlledSelect
          options={stackingStrategiesOptions}
          formState={formState}
          name='stackingStrategy'
          control={control}
          label={t('stackingStrategy', 'Stacking Strategy')}
        />

        <Stack flexDirection='row' alignItems='center' gap={1}>
          <Typography variant='subtitle1'>
            {t('assignEquipmentTypes', 'Assign Equipment Types')}
          </Typography>
          {type === 'Edit' && (
            <Tooltip
              title={t(
                'ifYouRemoveAnEquipmentTypeTheAssignmentsWillBeRemoved',
                'If you remove an equipment type, the assignments for that type will be automatically removed',
              )}
            >
              <InfoIcon color='secondary' />
            </Tooltip>
          )}
        </Stack>
        <ControlledMultipleChipSelect
          formState={formState}
          control={control}
          options={equipmentTypesOptions}
          name='equipmentTypes'
        />
        <Typography variant='subtitle1'>{t('usageOptions', 'Usage Options')}</Typography>
        <ControlledMultipleChipSelect
          formState={formState}
          control={control}
          options={usageOptions}
          name='usageOptions'
        />
      </FormGroup>
    </Box>
  )
})
