import { Stack, Typography } from '@mui/material'
import { AllocationRuleTemplateDtoFacets } from '@storage/app/api'
import { UnknownStringValue } from '@storage/app/models'
import { useTranslate } from '@tolgee/react'
import { GeneralIcon, useMinimalsTheme } from '@tom-ui/ui'

interface AllocationRuleTemplateFacetListProps {
  facets: AllocationRuleTemplateDtoFacets
}

const AllocationRuleTemplateFacetList = ({ facets }: AllocationRuleTemplateFacetListProps) => {
  const { t } = useTranslate()
  const { palette } = useMinimalsTheme()

  const facetElements = [
    facets.size && `${facets.size}′`,
    facets.isEmpty != null && (facets.isEmpty ? t('empty', 'Empty') : t('full', 'Full')),
    facets.isReefer != null &&
      (facets.isReefer ? t('reefer', 'Reefer') : t('nonReefer', 'Non Reefer')),
    facets.isDangerous != null &&
      (facets.isDangerous ? t('dangerous', 'Dangerous') : t('nonDangerous', 'Non Dangerous')),
    facets.containerOperator,
    facets.containerHeight,
    facets.containerType,
    facets.weightClasses?.length && facets.weightClasses.join(', '),
    facets.consignee &&
      `${facets.consignee === UnknownStringValue ? t('consignee', 'Consignee') + ': ' : ''}${facets.consignee}`,
    facets.portOfDischarge &&
      `${facets.portOfDischarge === UnknownStringValue ? t('portOfDischarge', 'Port of discharge') + ': ' : ''}${facets.portOfDischarge}`,
  ].filter(Boolean)

  return (
    <Stack direction='row'>
      <GeneralIcon sx={{ color: palette.grey[600], mr: 1 }} />
      {facets.containerNumber ? (
        <Typography color={palette.text.secondary} whiteSpace='nowrap' variant='body1'>
          {facets.containerNumber}
        </Typography>
      ) : (
        <Typography color={palette.text.secondary} variant='body1'>
          {facetElements.join('/')}
        </Typography>
      )}
    </Stack>
  )
}

export default AllocationRuleTemplateFacetList
