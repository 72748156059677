import { PieChart } from '@mui/x-charts/PieChart'

export interface PieItem {
  id: number
  label: string
  color: string
  value: number
}

interface Props {
  items: PieItem[]
}
export const PieGraph = ({ items }: Props) => {
  const getTotal = () => items.reduce((prev, curr) => prev + curr.value, 0)

  return (
    <PieChart
      series={[
        {
          data: items.map(x => ({ ...x, label: `${x.value} ${x.label}` })),
          highlightScope: { fade: 'global', highlight: 'item' },
          faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
          innerRadius: 50,
          valueFormatter: (item: { value: number }) =>
            `${Math.round((item.value / getTotal()) * 100)}%`,
        },
      ]}
      width={450}
      height={200}
    />
  )
}
