import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import { ContainerTurnoverDto, YardPositionDto } from '@storage/app/api'
import { usePositionLabel } from '@storage/app/container-turnovers/hooks/use-position-label.hook'
import { YardPositionInputContainer } from '@storage/features/yard-operation-control/Variants/YardPositionInput/YardPositionInputContainer'
import { useStores } from '@storage/hooks/use-stores.hook'
import { PATH_STORAGE } from '@storage/routes'
import { useTranslate } from '@tolgee/react'
import { CloseButton, Tile, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'

type Params = {
  containerTurnoverId: string
}

const OperatorContainerTurnoverPositionUpdate = observer(() => {
  const { t } = useTranslate()
  const { containerTurnoverId } = useParams<Params>()
  const [containerTurnover, setContainerTurnover] = useState<ContainerTurnoverDto>()

  const { operatorContainerTurnoverStore, containerTurnoverStore } = useStores()

  const navigate = useNavigate()
  const theme = useMinimalsTheme()

  useEffect(() => {
    const fetchTurnover = async () => {
      const response = await containerTurnoverStore.getContainerTurnover(containerTurnoverId!)
      setContainerTurnover(response)
    }
    fetchTurnover()
  }, [containerTurnoverId, containerTurnoverStore])

  const onChange = (yardPosition?: YardPositionDto) => {
    operatorContainerTurnoverStore.clearAlerts()
    operatorContainerTurnoverStore.setYardPosition(yardPosition)
  }

  const onConfirm = async () => {
    await operatorContainerTurnoverStore.updateContainerPosition(containerTurnoverId)

    if (operatorContainerTurnoverStore.validationAlerts.length === 0)
      navigate(PATH_STORAGE.operatorContainerTurnoversList.root)
  }

  const onClose = () => {
    operatorContainerTurnoverStore.clearAlerts()
    navigate(PATH_STORAGE.operatorContainerTurnoversList.root)
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CloseButton onClose={onClose} tooltip={t('close', 'Close')} />
          <Typography variant='h3' sx={{ marginLeft: '30px' }}>
            {containerTurnover?.containerNumber}
          </Typography>
        </Box>
        <Box sx={{ p: 2 }}>
          <Button
            variant='contained'
            color='primary'
            type='button'
            disabled={!operatorContainerTurnoverStore.yardPosition}
            onClick={onConfirm}
          >
            {t('confirm', 'Confirm')}
          </Button>
        </Box>
      </Box>
      <Box sx={{ marginLeft: '70px' }}>
        <Typography variant='subtitle1' sx={{ marginBottom: '10px' }}>
          {t('generalInformation', 'General information')}
        </Typography>
        <Stack direction='row' spacing={1} sx={{ flexWrap: 'wrap', gap: 1, marginBottom: '30px' }}>
          {containerTurnover?.referenceNumber && (
            <Tile label={t('refNumber', 'Ref')} value={containerTurnover?.referenceNumber} />
          )}
          <Tile
            label={t('cargoStatus', 'Cargo status ')}
            value={
              containerTurnover?.isEmptyContainerCargoState
                ? t('empty', 'Empty')
                : t('full', 'Full')
            }
          />
          {containerTurnover?.size && (
            <Tile label={t('size', 'Size')} value={`${containerTurnover?.size}'`} />
          )}
          {containerTurnover?.consignee && (
            <Tile label={t('consignee', 'Consignee')} value={containerTurnover.consignee} />
          )}
          {containerTurnover?.outboundCarriers && (
            <Tile
              key={containerTurnover?.outboundCarriers[0]?.name}
              label={t('vessel', 'Vessel')}
              value={containerTurnover?.outboundCarriers[0]?.name}
            />
          )}
          {containerTurnover?.portOfDischarge && (
            <Tile label={t('dischargePort', 'POD')} value={containerTurnover?.portOfDischarge} />
          )}
        </Stack>
      </Box>
      <Stack gap={theme.customSpacing.m} marginY={theme.customSpacing.m}>
        <Typography sx={{ marginLeft: '70px' }} variant='subtitle1'>
          {t('journey', 'Journey')}
        </Typography>
        <TextField
          label={t('origin', 'Origin')}
          value={usePositionLabel(containerTurnover?.currentPosition)[0]}
          disabled
          sx={{ marginLeft: '70px', width: '345px' }}
        />
        <YardPositionInputContainer
          onChange={onChange}
          turnover={containerTurnover}
          validationAlerts={operatorContainerTurnoverStore.validationAlerts}
        />
      </Stack>
    </Box>
  )
})

export default OperatorContainerTurnoverPositionUpdate
