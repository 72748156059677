import { ContainerHeight, ContainerType, IsoCodeMappingResponseDto } from '@admin/app/api'
import { useAdminStore } from '@admin/AppProvider'
import { Button, debounce } from '@mui/material'
import { Stack } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { Header, PlusIcon, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { IsoCodeMappingUIStore } from '../stores/iso-code-mapping.ui-store'

interface IProps {
  viewStore: IsoCodeMappingUIStore
  onFilter: (filter: string) => Promise<IsoCodeMappingResponseDto>
}

export const IsoCodeMappingHeader: FC<IProps> = observer(({ viewStore, onFilter }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { appStore } = useAdminStore()

  const filterDebounced = debounce(async (value: string) => {
    viewStore.setFilter(value)
    if (
      value.length === 4 &&
      !viewStore.isoCodeMappingStore.items.find(
        item => item.isoCode.toUpperCase() === value.toUpperCase(),
      )
    ) {
      try {
        appStore.increaseLoadingCounter()
        const standardIsoCode = await onFilter(value)
        viewStore.setFilteredStandardIsoCode(standardIsoCode)
      } finally {
        appStore.decreaseLoadingCounter()
      }
    } else {
      viewStore.setFilteredStandardIsoCode()
    }
  }, 500)

  return (
    <Header
      title={t('isoCodeMapping', 'ISO Code Mapping')}
      searchInputLabel={t(
        'searchForACustomOrStandardIsoCode',
        'Search for a custom or standard ISO code',
      )}
      onSearchFieldChange={filterDebounced}
      displaySearchIcon
      enableSearchField
      rightRenderOption={
        <Stack flexDirection='row' gap={theme.customSpacing.xs}>
          <Button
            color='inherit'
            variant='contained'
            sx={{ flex: 'none' }}
            startIcon={<PlusIcon />}
            onClick={() => {
              viewStore.toggleFormDialog(true, {
                isoCode: viewStore.filter,
                height: ContainerHeight.Standard,
                type: ContainerType.General,
              })
            }}
          >
            {t('addNew', 'Add new')}
          </Button>
        </Stack>
      }
    />
  )
})
