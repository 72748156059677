// eslint-disable-next-line @typescript-eslint/no-unused-vars
const STORAGE_PREFIX = 'storage'
export const LAYOUT = 'layout'
export const REGISTERS = 'registers'
export const JOBS = 'jobs'
export const OPERATOR_VIEWS = 'operatorViews'
export const GC_HUB = 'gc-hub'

export const PATHS = {
  dashboard: '/dashboard',
  profile: '/profile',
  jobs: {
    workInstructions: `${JOBS}/workInstructions`,
    reeferMonitoring: `${JOBS}/reeferMonitoring`,
  },
  registers: {
    vessels: `${REGISTERS}/vessels`,
    railcars: `${REGISTERS}/railcars`,
    reports: `${REGISTERS}/reports`,
    customers: `${REGISTERS}/customers`,
    users: `${REGISTERS}/users`,
    tenants: `${REGISTERS}/tenants`,
    tenantConfiguration: `${REGISTERS}/tenant-configuration`,
    equipments: `${REGISTERS}/Equipment`,
    reset: `${REGISTERS}/reset`,
    commoditiesAndPacking: `${REGISTERS}/commodities-packaging`,
    allocationRuleTemplates: `${REGISTERS}/allocation-rule-templates`,
    weightClasses: `${REGISTERS}/weight-classes`,
    damageCatalogue: `${REGISTERS}/damage-catalogue`,
    dangerousGoodsSettings: `${REGISTERS}/dangerous-goods-settings`,
    isoCodeMappings: `${REGISTERS}/iso-code-mapping`,
  },
  layout: {
    piers: `${LAYOUT}/${STORAGE_PREFIX}/piers`,
    berths: `${LAYOUT}/${STORAGE_PREFIX}/berths`,
    railTracks: `${LAYOUT}/${STORAGE_PREFIX}/rail-tracks`,
    yardSetup: `${LAYOUT}/${STORAGE_PREFIX}/yard-setup`,
  },

  //Thales's
  yard: `${STORAGE_PREFIX}/yard-management`,
  containerTurnovers: `${STORAGE_PREFIX}/container-turnovers`,
  yardPlanningDashboard: {
    root: `/${STORAGE_PREFIX}/yard-planning-dashboard`,
    details: {
      root: `/${STORAGE_PREFIX}/yard-planning-dashboard/:vesselVisitId/:handlingDirection/:discriminator`,
      params: {
        vesselVisitId: 'vesselVisitId',
        handlingDirection: 'handlingDirection',
        discriminator: 'discriminator',
      },
    },
  },
  yardPlanningIssues: `/${STORAGE_PREFIX}/yard-planning-issues`,
  yardInconsistencies: `/${STORAGE_PREFIX}/yard-inconsistencies`,
  operatorContainerTurnoversList: {
    root: `/${STORAGE_PREFIX}/operator-container-turnovers-list`,
    positionUpdate: {
      root: `/${STORAGE_PREFIX}/operator-container-turnovers-list/:containerTurnoverId`,
      params: {
        containerTurnoverId: 'containerTurnoverId',
      },
    },
  },
  generalCargoHub: {
    generalCargoAreas: `/${GC_HUB}/general-cargo-areas`,
    generalCargoInventory: `/${GC_HUB}/general-cargo-inventory`,
  },
  globalAllocationRules: `/${STORAGE_PREFIX}/global-allocation-rules`,

  //Octa's
  vesselVisits: '/vessel-visits',
  railVisits: '/rail-visits',
  visits: '/visits',
  gateControl: '/gate-control',
  containerVisits: '/container-visits',
  generalCargoOrders: '/general-cargo',
  releaseOrders: '/release-orders',
  nnrOrders: '/nnr-orders',
  nnrOrdersPageForVisit: '/nnr-orders/vesselVisit',
  deliveryOrders: '/delivery-orders',
  tallyclerk: '/tallyclerk',
  gateClerk: '/gateclerk',
  serviceOrders: '/service-orders',
  issues: '/issues',

  //Magic's
  crane: `${OPERATOR_VIEWS}/Crane/Landing`,
  tt: '/Operator/tt',
  rtg: '/Operator/rtg',
  rmg: '/Operator/rmg',
  rs: '/Operator/rs',
  sts: '/Operator/sts',
  ech: '/Operator/ech',
  all: '/Operator/all',
  gc: '/general-cargo-operator',
  reeferOperator: '/reefer-operator',
  testSign: '/testSign',
}
