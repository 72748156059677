import { Box, Stack, Typography } from '@mui/material'
import { ContainerResponseDto, UnitType } from '@planning/app/api'
import { getContainerTypeParameters } from '@planning/components/ContainerTypesMap'
import { DamagedContainerIcon } from '@planning/components/DamagedContainerIcon'
import { OrderStatusChip } from '@planning/components/OrderStatusChip'
import { useUnitTypeIcon } from '@planning/components/UnitType'
import { emptyIndicator } from '@planning/constants'
import { useTranslate } from '@tolgee/react'
import { EditIcon, IconButton } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

type Props = {
  container: ContainerResponseDto
  isDamaged: boolean
  onClickEdit?: () => void
}

export const ContainerVisitsCardHeader: FC<Props> = observer(
  ({ container, isDamaged, onClickEdit }) => {
    const { t } = useTranslate()

    let icon

    if (container.unitType === UnitType.Container) {
      const { icon: containerIcon } = getContainerTypeParameters(container.type, t)
      icon = containerIcon
    } else {
      icon = useUnitTypeIcon(container.unitType)
    }

    return (
      <Box sx={{ padding: '1rem', display: 'flex', justifyContent: 'space-between' }}>
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          {icon}
          <Typography variant='h4'>{container.number}</Typography>

          {container.isoCode && <OrderStatusChip status={container.isoCode || ''} />}
          {container.type && <OrderStatusChip status={container.type} />}
          {container.length && (
            <OrderStatusChip
              status={`${container.length ? container.length + 'ft' : emptyIndicator}`}
            />
          )}
          {container.height && <OrderStatusChip status={container.height || ''} />}
          {container.maxGross && (
            <OrderStatusChip status={`${t('maxGross', 'MaxGross')}: ${container.maxGross}`} />
          )}
          {isDamaged && <DamagedContainerIcon />}
        </Stack>

        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          {onClickEdit && (
            <IconButton variant='text' onClick={onClickEdit}>
              <EditIcon />
            </IconButton>
          )}
        </Stack>
      </Box>
    )
  },
)
