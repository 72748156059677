import {
  CreateTenantCommand,
  TenantConfigDto,
  TenantDto,
  TenantsApi,
  UpdateTenantCommand,
  UpdateTenantReeferContainerMaxUnpluggedTimeCommand,
} from '@admin/app/api'
import { computed, makeObservable, observable, runInAction } from 'mobx'
import { BaseEntityStore } from './base/BaseEntityStore'

export class TenantStore extends BaseEntityStore<TenantDto> {
  currenTenantConfig?: TenantConfigDto

  constructor(private api: TenantsApi) {
    super()
    makeObservable(this, {
      currenTenantConfig: observable,

      skipCraneSplit: computed,
      skipVesselTally: computed,
      skipRailTally: computed,
      showUnifiedOperatorPage: computed,
      hasGeneralCargo: computed,
      hasDamageCatalogue: computed,
    })
  }

  async load() {
    const { data } = await this.api.tenantsGet()
    this.updateStoreItems(data)
  }

  async loadOne(tenantId: number) {
    const { data } = await this.api.tenantsGetOne(tenantId)
    if (data) this.updateStoreItem(data, tenantId)
  }

  async add(createTenantCommand: CreateTenantCommand) {
    const { data } = await this.api.tenantsPost(createTenantCommand)
    this.updateStoreItem(data, data.id)
  }

  async update(updateTenantCommand: UpdateTenantCommand) {
    const { data } = await this.api.tenantsPut(updateTenantCommand)

    this.updateStoreItem(data, data.id)
  }

  async updateReeferContainerMaxUnpluggedTime(
    updateTenantReeferContainerMaxUnpluggedTimeCommand: UpdateTenantReeferContainerMaxUnpluggedTimeCommand,
  ) {
    const { data } = await this.api.tenantsUpdateReeferContainerMaxUnpluggedTime(
      updateTenantReeferContainerMaxUnpluggedTimeCommand,
    )

    this.updateStoreItem(data, data.id)
  }

  async delete(id: number) {
    await this.api.tenantsDelete(id)
    this.deleteStoreItem(id)
  }

  async loadConfigs() {
    const { data } = await this.api.tenantsGetCurrentConfigs()

    runInAction(() => {
      this.currenTenantConfig = data
    })
  }

  public get skipCraneSplit(): boolean {
    return this.currenTenantConfig?.skipCraneSplit ?? false
  }

  public get skipVesselTally(): boolean {
    return this.currenTenantConfig?.skipVesselTally ?? false
  }

  public get skipRailTally(): boolean {
    return this.currenTenantConfig?.skipRailTally ?? false
  }

  public get showUnifiedOperatorPage(): boolean {
    return this.currenTenantConfig?.showUnifiedOperatorPage ?? false
  }

  public get hasGeneralCargo(): boolean {
    return this.currenTenantConfig?.hasGeneralCargo ?? false
  }

  public get hasDamageCatalogue(): boolean {
    return this.currenTenantConfig?.hasDamageCatalogue ?? false
  }
}
