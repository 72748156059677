import { Button, Card, CardContent, CardHeader, Stack, Typography } from '@mui/material'
import { formatContainerNumber } from '@operations/utils/helper'
import { useTranslate } from '@tolgee/react'
import { ChevronLeftIcon, ChevronRightIcon, IconButton, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

import { useState } from 'react'
import { CoolingOrderCardDto } from '../../models/cooling-order-model'
import { ReeferMonitoringDetailsUIStore } from '../../stores/reefer-monitoring-details.ui-store'
import { AlertNameIcon } from './AlertNameIcon'

interface Props {
  coolingOrder: CoolingOrderCardDto
  isForDetails?: boolean
  reeferMonitoringDetailsUIStore?: ReeferMonitoringDetailsUIStore
}
export const AlertsCard = observer(
  ({ coolingOrder, isForDetails, reeferMonitoringDetailsUIStore }: Props) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()
    const [currentAlertIndex, setCurrentAlertIndex] = useState<number>(0)

    const totalAlert = coolingOrder.alerts.length
    if (totalAlert === 0) {
      return <></>
    }
    const emptyIndicator = ' - '

    const orderAlerts = AlertNameIcon(coolingOrder.alerts, t)

    return (
      <Card
        sx={{
          boxShadow: 'none',
          border: 'none',
          borderRadius: isForDetails ? undefined : 0,
          background: isForDetails ? theme.palette.secondary.light : undefined,
        }}
      >
        {!isForDetails && (
          <CardHeader
            title={
              <Typography variant='subtitle2'>
                {coolingOrder.container.containerNumber
                  ? formatContainerNumber(coolingOrder.container.containerNumber)
                  : emptyIndicator}
              </Typography>
            }
            action={
              <Button
                variant='contained'
                color='inherit'
                onClick={() => reeferMonitoringDetailsUIStore?.toggle(coolingOrder.id)}
              >
                {t('open', 'Open')}
              </Button>
            }
          />
        )}
        <CardContent sx={isForDetails ? { padding: theme.customSpacing.m } : { paddingTop: 0 }}>
          <Stack flexDirection='row' gap={theme.customSpacing.s} alignItems='center'>
            {orderAlerts[currentAlertIndex].icon}
            <Stack flexDirection='column' gap={theme.customSpacing.xxs} flexWrap='wrap'>
              <Stack flexDirection='row' gap={theme.customSpacing.s} flexWrap='wrap'>
                <Typography variant='subtitle1'>
                  {currentAlertIndex + 1} of {totalAlert} {t('deviations', 'deviations')}
                </Typography>
                <Stack flexDirection='row' gap={theme.customSpacing.xxs} flexWrap='wrap'>
                  <IconButton
                    style={{ padding: 0 }}
                    size='small'
                    disabled={currentAlertIndex <= 0}
                    onClick={() => setCurrentAlertIndex(currentAlertIndex - 1)}
                  >
                    <ChevronLeftIcon fontSize='small' />
                  </IconButton>
                  <IconButton
                    style={{ padding: 0 }}
                    size='small'
                    disabled={currentAlertIndex >= totalAlert - 1}
                    onClick={() => setCurrentAlertIndex(currentAlertIndex + 1)}
                  >
                    <ChevronRightIcon fontSize='small' />
                  </IconButton>
                </Stack>
              </Stack>
              {isForDetails ? (
                <Typography variant='body1'>{orderAlerts[currentAlertIndex].label}</Typography>
              ) : (
                <Typography variant='body2' color='secondary'>
                  {orderAlerts[currentAlertIndex].label}
                </Typography>
              )}
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    )
  },
)
