import { Warehouse } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { ContainerPositionType, JobDto, JobDtoFrom } from '@operations/app/api'
import { useTranslate } from '@tolgee/react'
import {
  LongArrowRight,
  TrainIcon,
  TruckIcon,
  useMinimalsTheme,
  VesselIcon,
  YardIcon,
} from '@tom-ui/ui'

export interface Props {
  job: JobDto
  currentEquipmentId?: number
}
export const JobJourneyMinimalistic = ({ job, currentEquipmentId }: Props) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  const isNotOnOrigin = job.container?.currentLocation.type !== job.origin.type

  return (
    <Stack
      flexDirection='row'
      alignItems='center'
      gap={theme.customSpacing.m}
      divider={<LongArrowRight fontSize='large' />}
      flexWrap='wrap'
    >
      <Location location={job.origin} finished={isNotOnOrigin} dataCy='job-card-origin' />

      {job.container?.currentLocation.type === ContainerPositionType.Equipment &&
        job.container.currentLocation.equipmentId !== currentEquipmentId && (
          <Location location={job.container.currentLocation} />
        )}
      {job.container?.currentLocation.type === ContainerPositionType.Handover && (
        <Location
          location={{
            type: ContainerPositionType.Handover,
            locationName: t('equipment', ' Equipment'),
            yardBlockName: '',
          }}
          finished
        />
      )}

      <Location location={job.destination} dataCy='job-card-destination' />
    </Stack>
  )
}

interface LocationProps {
  location: JobDtoFrom
  finished?: boolean
  dataCy?: string
}
const Location = ({ location, finished, dataCy }: LocationProps) => {
  const theme = useMinimalsTheme()
  let icon = <YardIcon fontSize='small' color={finished ? 'secondary' : 'inherit'} />

  switch (location.type) {
    case ContainerPositionType.Vessel:
    case ContainerPositionType.Berth:
      icon = <VesselIcon fontSize='small' color={finished ? 'secondary' : 'inherit'} />
      break
    case ContainerPositionType.Train:
      icon = <TrainIcon fontSize='small' color={finished ? 'secondary' : 'inherit'} />
      break
    case ContainerPositionType.Truck:
    case ContainerPositionType.Equipment:
    case ContainerPositionType.Handover:
      icon = <TruckIcon fontSize='small' color={finished ? 'secondary' : 'inherit'} />
      break
    case ContainerPositionType.Warehouse:
    case ContainerPositionType.Customs:
    case ContainerPositionType.Weights:
    case ContainerPositionType.Repair:
      icon = <Warehouse fontSize='small' color={finished ? 'secondary' : 'inherit'} />
      break
  }

  return (
    <Stack flexDirection='row' gap={theme.customSpacing.xs}>
      {icon}
      <Typography variant='body1' color={finished ? 'secondary' : 'inherit'} data-cy={dataCy}>
        {location.locationName}
      </Typography>
    </Stack>
  )
}
