import * as yup from 'yup'
import { ReeferMonitoringFormProfile } from './reefer-monitoring-form.profile'

export const schema = (): yup.Schema<ReeferMonitoringFormProfile> =>
  yup.object({
    cargoUnitId: yup.number().required(),
    isPluggedIn: yup.boolean().required().label('Plugged in'),
    temperature: yup
      .string()
      .label('Temperature')
      .when('isTemperatureRequired', ([isTemperatureRequired], schema) =>
        isTemperatureRequired ? schema.required() : schema.notRequired(),
      ),
    humidity: yup.string().label('Humidity').notRequired(),
    ventilation: yup.string().label('Ventilation').notRequired(),
    recordedAt: yup.date().required().label('Recorded at'),
    additionalInfo: yup.string().label('Additional error info').emptyAsUndefined(),
  })
