import { Button, Stack } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { PlusIcon, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { Control, FormState, useFieldArray } from 'react-hook-form'
import {
  getDefaultContainerDamage,
  IContainerDamageFormData,
  IContainerDamageOption,
} from './ContainerDamage.model'
import { ContainerDamageReportFields } from './ContainerDamageReportFields'

interface IProps {
  control: Control<IContainerDamageFormData>
  formState: FormState<IContainerDamageFormData>
  hasDamageCatalogue?: boolean
  canRemoveDamageFields?: boolean
  containerDamageLocations: IContainerDamageOption[]
  containerDamageTypes: IContainerDamageOption[]
}
export const ContainerDamageReportForm = observer(
  ({
    control,
    formState,
    hasDamageCatalogue,
    canRemoveDamageFields,
    containerDamageLocations,
    containerDamageTypes,
  }: IProps) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    const { fields, append, remove, update } = useFieldArray({
      control: control,
      name: 'damagesReported',
    })

    return (
      <Stack gap={theme.customSpacing.m}>
        {fields.map((field, index) => (
          <ContainerDamageReportFields
            key={field.id}
            index={index}
            control={control}
            formState={formState}
            containerDamageLocations={containerDamageLocations}
            containerDamageTypes={containerDamageTypes}
            hasDamageCatalogue={hasDamageCatalogue}
            canRemoveDamageFields={canRemoveDamageFields}
            removeDamage={remove}
            clearFields={update}
          />
        ))}

        {hasDamageCatalogue && (
          <Button
            variant='text'
            color='primary'
            endIcon={<PlusIcon />}
            onClick={() => append(getDefaultContainerDamage())}
          >
            {t('addAnotherDamage', 'Add another damage')}
          </Button>
        )}
      </Stack>
    )
  },
)
