import {
  AssignRailcarTrackPositionCommand,
  CargoType,
  CarrierVisitStatus,
  CheckLoadPreparationCommand,
  CheckRailcarForDischargeCommand,
  CreateDepartureCheckRailcarCommand,
  CreateRailcarAndOrdersCommand,
  CreateRailVisitCommand,
  DeleteRailcarTrackPositionCommand,
  RailVisitsApi,
  ReportRailcarTrackPositionMissingCommand,
  UpdateRailcarSequenceCommand,
  UpdateRailVisitCommand,
} from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'

class RailVisitService {
  httpClient = createApiClient(RailVisitsApi)

  async get(
    page: number,
    pageSize: number,
    filter?: string,
    cargoType?: CargoType,
    includeAtaDateRangeFilter?: boolean,
  ) {
    const { data } = await this.httpClient.get(
      page,
      pageSize,
      undefined,
      cargoType,
      includeAtaDateRangeFilter,
      filter,
    )

    return data
  }

  async getById(id: number) {
    const { data } = await this.httpClient.getById(id)

    return data
  }

  async post(cmd: CreateRailVisitCommand) {
    const { data } = await this.httpClient.create(cmd)
    return data
  }

  async put(cmd: UpdateRailVisitCommand) {
    const { data } = await this.httpClient.put(cmd)
    return data
  }

  async startOperations(railVisitId: number) {
    await this.httpClient.updateRailVisitStatus(railVisitId, {
      id: railVisitId,
      status: CarrierVisitStatus.InOperation,
    })
  }

  getByIds = async (ids: Array<number>) => {
    const { data } = await this.httpClient.getByIds(ids)

    return data
  }

  updateRailcarSequence = async (cmd: UpdateRailcarSequenceCommand) => {
    await this.httpClient.updateRailcarSequence(cmd)
  }

  checkRailcarForDischarge = async (cmd: CheckRailcarForDischargeCommand) => {
    await this.httpClient.checkRailcarForDischarge(cmd)
  }

  createRailcarAndOrders = async (cmd: CreateRailcarAndOrdersCommand) => {
    await this.httpClient.createRailcarAndOrders(cmd)
  }

  deleteRailCar = async (cmd: DeleteRailcarTrackPositionCommand) => {
    const { data } = await this.httpClient.deleteRailcarTrackPosition(cmd)
    return data
  }

  checkLoadPreparation = async (cmd: CheckLoadPreparationCommand) => {
    await this.httpClient.checkLoadPreparation(cmd)
  }

  createDepartureCheck = async (cmd: CreateDepartureCheckRailcarCommand) => {
    await this.httpClient.departureCheck(cmd)
  }

  assignRailcarTrackPosition = async (cmd: AssignRailcarTrackPositionCommand) => {
    await this.httpClient.assignRailcarTrackPosition(cmd)
  }

  reportMissingRailcarTrackPosition = async (cmd: ReportRailcarTrackPositionMissingCommand) => {
    await this.httpClient.reportMissingRailcar(cmd)
  }
}

const railVisitService = new RailVisitService()

export default railVisitService
