import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { RailcarsFormDialog } from '@planning/pages/Railcars/components/RailcarsFormDialog'
import { RailcarsViewStore } from '@planning/pages/Railcars/stores/RailcarsViewStore'
import { formatRailcarName } from '@planning/utils/railcar-utils'
import { useTranslate } from '@tolgee/react'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'

interface IProps {
  hasError: boolean
  label: string
  value?: number
  onChange: (railcarId?: number) => void
}

export const RailcarAutocomplete = observer(({ hasError, label, value, onChange }: IProps) => {
  const { t } = useTranslate()

  const { railcarItemStore, appViewStore } = usePlanningStore()

  const railcarsViewStore = useMemo(
    () => new RailcarsViewStore(railcarItemStore, appViewStore),
    [railcarItemStore, appViewStore],
  )

  useEffect(() => {
    railcarItemStore.fetch()
  }, [railcarItemStore])

  const options = computed(() =>
    railcarItemStore.getRailcars().map(rc => ({ ...rc, name: formatRailcarName(rc.name) })),
  ).get()

  return (
    <>
      <Autocomplete
        disablePortal
        getOptionLabel={railcarPosition => railcarPosition?.name}
        options={options}
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        value={options.find(x => x?.id === value) ?? null}
        onChange={(_, railcarPosition) => {
          onChange(railcarPosition?.id)
        }}
        noOptionsText={
          <Box p={2} textAlign='center'>
            <Typography variant='body2'>{t('noRailcarFound', 'No railcar found')}</Typography>
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                railcarsViewStore.toggleDialog(true)
              }}
            >
              {t('createRailcar', 'Create Railcar')}
            </Button>
          </Box>
        }
        renderInput={params => (
          <TextField
            {...params}
            data-cy='railcar-autocomplete'
            onChange={e => {
              railcarsViewStore.setDefaultRailcarNumber(e.target.value)
            }}
            label={label}
            error={hasError}
            helperText={hasError ? t('fieldIsRequired', 'Field is required') : undefined}
          />
        )}
      />
      <RailcarsFormDialog store={railcarsViewStore} />
    </>
  )
})
