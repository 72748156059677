import { Typography } from '@mui/material'
import { JobDto } from '@operations/app/api'
import { formatContainerNumber } from '@operations/utils/helper'
import { useTranslate } from '@tolgee/react'
import { HighlightedText } from '@tom-ui/ui'

interface Props {
  job: JobDto
  highlightText?: string
  variant?: 'h3' | 'h1'
}
export const JobContainerNumber = ({ job, highlightText, variant }: Props) => {
  const { t } = useTranslate()

  return (
    <Typography variant={variant ?? 'h3'} data-cy='job-card-container-number'>
      <HighlightedText highlight={highlightText}>
        {job.container?.containerNumber
          ? formatContainerNumber(job.container.containerNumber)
          : t('noContainerNumber', 'No container no.')}
      </HighlightedText>
    </Typography>
  )
}
