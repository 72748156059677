import { LANGUAGE_LOCALSTORAGE } from '@host/components/LangSelector'
import * as Sentry from '@sentry/react'
import { DevTools, FormatSimple, Tolgee, TolgeeProvider } from '@tolgee/react'
import { AdminStoreProvider } from '@tom-ui/admin'
import { BillingServiceStoreProvider } from '@tom-ui/billing'
import { OperationsStoreProvider } from '@tom-ui/operations'
import { PlanningStoreProvider } from '@tom-ui/planning/AppProvider'
import {
  RootStore as StorageRootStore,
  StoreProvider as StorageStoreProvider,
} from '@tom-ui/storage'
import { ThemeProvider } from '@tom-ui/ui'
import { Auth0ProviderWithHistory, getEnvName, UtilsStoreProvider } from '@tom-ui/utils'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import { App } from './App'
import deLang from './i18n/de.json'
import enLang from './i18n/en.json'

const envName = getEnvName()
const mainAppSentryDns =
  'https://40ceaed00cff4203941b61553223e8db@o4505073926406144.ingest.sentry.io/4505397101658112'

if (envName !== 'local') {
  Sentry.init({
    dsn: mainAppSentryDns,
    beforeSend(event, hint) {
      const originalException = hint.originalException as any

      if (originalException?.name === 'AxiosError' && originalException?.response?.status < 500) {
        return null
      }

      return event
    },
    environment: envName,
  })
}

const defaultLanguage = localStorage.getItem(LANGUAGE_LOCALSTORAGE)

const tolgee = Tolgee()
  .use(DevTools())
  .use(FormatSimple())
  .init({
    language: defaultLanguage ?? 'en',

    // for development
    apiUrl: 'https://app.tolgee.io',
    apiKey: 'tgpak_gi2tsms7gi3ha23gnntdgnzunnrdo4teofrximbtgnsheojynu',

    // for production
    staticData: {
      en: enLang,
      de: deLang,
    },
  })

export const RoutedApp = () => (
  <BrowserRouter>
    <Auth0ProviderWithHistory>
      <HelmetProvider>
        <Helmet>
          <title>Tom</title>
        </Helmet>
        <ThemeProvider>
          <StorageStoreProvider store={new StorageRootStore()}>
            <OperationsStoreProvider>
              <PlanningStoreProvider>
                <AdminStoreProvider>
                  <BillingServiceStoreProvider>
                    <UtilsStoreProvider>
                      <TolgeeProvider
                        tolgee={tolgee}
                        fallback='Loading...' // loading fallback
                      >
                        <App />
                      </TolgeeProvider>
                    </UtilsStoreProvider>
                  </BillingServiceStoreProvider>
                </AdminStoreProvider>
              </PlanningStoreProvider>
            </OperationsStoreProvider>
          </StorageStoreProvider>
        </ThemeProvider>
      </HelmetProvider>
    </Auth0ProviderWithHistory>
  </BrowserRouter>
)
